// import logo from './logo.svg';
const  iconv = require('iconv-lite');


import fs from 'fs'
import {useEffect,useState,useRef} from 'react'
import ReactLoading from 'react-loading';

// import { Modal,Button,Tag,Card,PageHeader } from 'antd';


import SwiperCore, { Navigation, Pagination, Scrollbar, A11y } from 'swiper';

import { Swiper, SwiperSlide } from 'swiper/react';

import isOnline from 'is-online';

import NetworkSpeed from 'network-speed'; // ES6

import axios from 'axios';


// Import Swiper styles
import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/pagination/pagination.scss';
import 'swiper/components/scrollbar/scrollbar.scss';


// import Swiper styles
// import 'swiper/swiper-bundle.css';

import vectorImg from '../images/Vector.png'

import {
    Redirect,useHistory,useLocation
  } from "react-router-dom";
// import './Counter.css';
// import './jquery.modal.min.css'
// import './reset.css'
import '../common-css/standard.css'
// import './standard.less'
// import './swiper.css'
// import './swiper.min.css'
// import 'antd/dist/antd.css'; // or 'antd/dist/antd.less'
import api from  '../../services/api';
import OrderComponent from './OrderComponent';
import logoutImage from '../images/svg/sign-out-alt.svg';

import { type } from 'os';
import { callbackify } from 'util';
import internal from 'stream';

var IIOTAPISDK = require('../../utils/sdk');


var sdk = new IIOTAPISDK({ endpoint: 'https://msg-beta.quickclick.cc' });
// SI for 2
var config = {
  iiotAccessKeyId: 'S_202104141752',
  iiotSecretKey: 'bf30b211177b160696dd15fda9d2beef4474b0f0',
};

SwiperCore.use([Navigation, Pagination, Scrollbar, A11y]);


const testNetworkSpeed = new NetworkSpeed();



async function getNetworkDownloadSpeed() {
  try{
    const baseUrl = 'https://eu.httpbin.org/stream-bytes/500000';
    const fileSizeInBytes = 500000;
    const speed = await testNetworkSpeed.checkDownloadSpeed(baseUrl, fileSizeInBytes);
    if(speed){
      return(speed)
    }else{
      return 0
    }
  }catch(e){
    console.log('e:::',e)
    throw e
  }
}

// async function getNetworkUploadSpeed() {
//   const options = {
//     hostname: 'www.google.com',
//     port: 80,
//     path: '/catchers/544b09b4599c1d0200000289',
//     method: 'POST',
//     headers: {
//       'Content-Type': 'application/json',
//     },
//   };
//   const fileSizeInBytes = 2000000
//   const speed = await testNetworkSpeed.checkUploadSpeed(options, fileSizeInBytes);
//   console.log('speed2:',speed);
//   return(speed)
// }


var request = window.indexedDB.open('MyTestDatabase', 5);
var objectStore;
var db;
var indexedDBCanWork = false

request.onsuccess = function (event) {
  db = request.result;
  console.log('indexedDB打開成功');
  indexedDBCanWork = true
};

request.onupgradeneeded = function(event) {
  db = event.target.result;
  if (!db.objectStoreNames.contains('order')) {
    objectStore = db.createObjectStore('order', { autoIncrement: true });
  }
}

function storeToIndexedDB(order) {
  // var request = db.transaction(['order'], 'readwrite')
  //   .objectStore('order')
  //   .add(order);
  var request = db.transaction(['order'], 'readwrite')
    .objectStore('order')
    .put(order,1);


  request.onsuccess = function (event) {
    console.log('Indexed數據寫入成功');
  };

  request.onerror = function (event) {
    console.log('Indexed數據寫入失敗');
  }
}



function read(setOrders,db,setSumMoney,setOrderIndex,setProductState) {
  var transaction = db.transaction(['order']);
  var objectStore = transaction.objectStore('order');
  var request = objectStore.get(1);

  request.onerror = function(event) {
    console.log('讀取indexdb失敗');
  };

  request.onsuccess = function( event) {
     if (request.result) {
      //  console.log('RRR: ' + JSON.stringify(request.result));
       let orders = request.result
       console.log('離線讀取orders:',orders)
       if(orders&&orders.length>0){
          setOrders(orders)
          setOrderIndex(orders.length-1)
          setProductState(orders[orders.length-1].id)
          setSumMoney(0)
          let addMoney = (number) => setSumMoney((prev) => prev + number)
  
          orders.forEach((order)=>{
            if(order){
              addMoney(order.count?order.count*order.price:1*order.price)
            }
          })
         
       }
       
       
      //  console.log('Age: ' + request.result.age);
      //  console.log('Email: ' + request.result.email);
     } else {
       console.log('indexeddb未獲得數據紀錄');
     }
  };
}

function removeIndexeddbData() {
  var request = db.transaction(['order'], 'readwrite')
    .objectStore('order')
    .delete(1);

  request.onsuccess = function (event) {
    console.log('indexeddb數據刪除成功');
  };
}

function readAll() {
  var objectStore = db.transaction('order').objectStore('order');

   objectStore.openCursor().onsuccess = function (event) {
     var cursor = event.target.result;

     if (cursor) {
       console.log('Cursor', cursor)
       console.log('Id: ' + cursor.key);
       console.log('Value', cursor.value);
  
       cursor.continue();
    } else {
      console.log('indexeddb沒有更多數據了！');
    }
  };
}



function updateIndexedDB(newOrder) {
  var request = db.transaction(['order'], 'readwrite')
    .objectStore('order')
    .put(newOrder,1);

  request.onsuccess = function (event) {
    console.log(newOrder)
    console.log('indexeddb數據更新成功');
  };

  request.onerror = function (event) {
    console.log('indexeddb數據更新失敗');
  }
}









function Counter() {
  const history = useHistory();
  const location = useLocation();
 
  // let account_id = location.pathname.split('/')[location.pathname.split('/').length-1]
  // if(isNaN(parseInt(account_id))){
  //   account_id = ''
  // }
  

  const [data,setData]=useState([]);
  // const [clickstate,setClickstate]=useState([{
  //   categoryId:'',
  //   productId:'',
  //   modifierGroupId:''
  // }])
  // let [account_id,setAccount_id] = useState(location.pathname.split('/')[location.pathname.split('/').length-1])
  const [account_id_from_session,setAccount_id_from_session] = useState('')
  const [user_info,setUser_info] = useState({})
  const [categorytate,setCategorytate] = useState('')
  const [productState,setProductState] = useState('')
  const [optionID,setOptionID] = useState('')
  const [groupState,setGroupState] = useState(new Array(16))
  const [modifierState,setModifierState] = useState()
  const [sequence,setSequence] = useState(new Array(20).fill({title:''}))
  const [sumMoney,setSumMoney] = useState(0)
  const [visible, setVisible] = useState(false);
  const [orders,setOrders] = useState([])
  
  const [date,setDate] = useState(new Date())

  const [tables,setTables] = useState([])
  const [leftSide,setLeftSide] = useState('inside')
  const [tableNumber,setTableNumber] = useState('')
  const [shopId,setShopId] = useState('')
  const [finishMsg,setFinishMsg] = useState(false)
  const [postOrderInfo,setPostOrderInfo] = useState({})
  const [modifierOptionMsg,setModifierOptionMsg] = useState(false)
  const [secondLayerModifierState,setSecondLayerModifierState] = useState([])
  const [noteMsg,setNoteMsg] = useState(false)
  const [noteTextArea,setNoteTextArea] = useState('')
  
  const [orderIndex,setOrderIndex] = useState()
  const [secondModifierTemp,setSecondModifierTemp] = useState({})
  const [offlineMsg,setOfflineMsg] = useState([false,'',''])
  const [discountMsg,setDiscountMsg] = useState(false)

  const [discountPercentSum,setDiscountPercentSum] = useState('') //其實要10
  const [discountMoneySum,setDiscountMoneySum] = useState('') //其實要0
  const [discountSum,setDiscountSum] = useState(0)
  const [discountWholeWay,setDiscountWholeWay] = useState('manual_whole')
  const [discountSumWay,setDiscountSumWay] = useState('money')

  const [requiredMsg,setRequiredMsg] = useState(false)
  const [tempClickModifier,setTempClickModifier] = useState([])
  const [modifierColor,setModifierColor] = useState(['white','#fed592','#9ed7f8','#aec5ff','#86e9de','#d7cdca','#d1c4e8','#febb96','#feabd2'])

  const [isFullScreen,setIsFullScreen] = useState(false)
  const [repeatOrder,setRepeatOrder] = useState(false)
  const [tempGroupIdForRadio,setTempGroupIdForRedio] = useState({})

  const [menu,setMenu] = useState({})
  const [errorOrderMsg,setErrorOrderMsg]= useState(false)

  const [swiper,setSwiper] = useState(null)
  const [orderIndexPre,setOrderIndexPre] = useState()
  const [shopName,setShopName] = useState('')
  const [loadingMsg,setLoadingMsg] = useState(false)

  const [ordersShow,setOrdersShow] = useState(false)
  const [errorOrderMsgContent,setErrorOrderMsgContent] = useState('')

  const [packageModifierMsg,setPackageModifierMsg] = useState(false)

  const [packageModifier,setPackageModifier] = useState([])
  const [packageNote,setPackageNote] = useState('')
  const [packageNoteMsg,setPackageNoteMsg] = useState(false)
  const [packageNoteTextArea,setPackageNoteTextArea] = useState('')
  const [packageMoney,setPackageMoney] = useState(0)
  const [packageDivGrayAnimationLeft,setPackageDivGrayAnimationLeft] = useState('-25%')
  const [packageDivGrayShow,setPackageDivGrayShow] = useState(false)

  const [wifiSpeed,setWifiSpeed] = useState(0)

  const [actionLogs,setActionLogs] = useState([])

  const [isDDCInvoiceAccount,setIsDDCInvoiceAccount] = useState(false)
  const [carrierId,setCarrierId] = useState('')
  const [taxId,setTaxId] = useState('')

  const [platformSelectMsg,setPlatformSelectMsg] = useState(false)




  let bottomDiv = useRef(null)


  useEffect(()=>{
    console.log('actionLogs::',actionLogs)
  },[actionLogs])

  useEffect(()=>{
    async function testWifiSpeed(){
      try{
        let isOnlineNow = await isOnline()
        if(!isOnlineNow){
            throw new Error('沒有網路')
        }
        let downloadSpeed = await getNetworkDownloadSpeed();
        // console.log(downloadSpeed.mbps)
        if(downloadSpeed&&downloadSpeed.mbps){
          setWifiSpeed(Math.round(Number(downloadSpeed.mbps)))
        }else{
          setWifiSpeed('0')
        }
      }catch(e){
        setWifiSpeed('0')
        console.log('setWifiSpeed 0:',wifiSpeed)
        console.log('testWifiSpeed e:::',e)
      }
    }

    // testWifiSpeed()
    return(setInterval(testWifiSpeed,3000))
  },[])

  useEffect(()=>{
    if(packageDivGrayShow){
      slidePackageSettingShow()
    }else{
      slidePackageSettingImplicit()
    }

  },[packageDivGrayShow])

  useEffect(()=>{
     watchFullScreen()

  },[])


  useEffect(()=>{
      async function checkLogin(){
          let result = await api.checkLoginApi()
          let result2 = await api.enauth()

          console.log('enauth::',result2)

          if(result&&result.account_id){
              // history.push(`/counter/${result.account_id}`);
              console.log('login:::',result.account_id)
              setAccount_id_from_session(result.account_id)
              setUser_info(result)
              await determineIsDDCInvoiceStore(result.account_id)
              
          }else{
            history.push(`/login`)
          }
      }
      checkLogin()

  },[])


  useEffect(()=>{
    // if(account_id&&!isNaN(parseInt(account_id))){
      console.log('account_id_from_session:',account_id_from_session)
    if(account_id_from_session){
        console.log('exist')
          updateTime()
          readMenuAndIndexDB()
    }else{
      
    }
        // }
  },[account_id_from_session])


  useEffect(()=>{

    async function renewSequenceTable(){

      console.log('productState變動，renewSequenceTable')

      if(data.products&&productState){

        // 判斷一層選單必選有無選，沒選跳modal提示
        // if(orders[orderIndex]){

        // }
        swiper.slideTo(0)
        

        let productId = productState

        let proGroup = data.products.filter((p)=>{
          return p.id==productId
        })[0]

        

        if(proGroup){
          proGroup=proGroup.modifierGroupIds
        }else{
          proGroup=undefined
        }

        // 為了測試多加購（到第二頁），製造假數據測試，上code要comment掉！！！
        // if(proGroup&&proGroup.length){
        //   proGroup = proGroup.map((ptemp)=>{
        //     return {...ptemp,modifierOptionIds:[...ptemp.modifierOptionIds,...ptemp.modifierOptionIds,...ptemp.modifierOptionIds,...ptemp.modifierOptionIds,...ptemp.modifierOptionIds,...ptemp.modifierOptionIds]}
        //   })
        //   console.log('proGroup::',proGroup)
        // }

        
        
        
        // 如果有排序過的，就用排序過的，剩下才用預設排序，一個個撈出來放
        let api_sequences = await api.sequenceApiGet(account_id_from_session)

        let sequence_items = []
        if(api_sequences&&api_sequences.result){
          sequence_items = api_sequences.result.filter((s)=>{

            return s.option_id == optionID
          })
        }
        if(sequence_items&&sequence_items.length>0){
          let pageNumber = api_sequences.result.length>19?Math.ceil((api_sequences.result.length-19)/20) + 1:1
          let newS = new Array(pageNumber*20).fill({item_name:''})
          newS[19] = {item_name:'單品備註',speical_tag:'單品備註'}
          // newS[18] = {item_name:'折扣',speical_tag:'折扣'}
          // newS[17] = {item_name:'整單加購',speical_tag:'加購'}
      
          sequence_items.map((api_sequence,i)=>{
            if(i<19){
              newS[api_sequence.sequence_id]={
                item_name:api_sequence.item_name,
                item_id:api_sequence.item_id,
                price:api_sequence.price,
                sequence_id:api_sequence.sequence_id,
              }
            }else{
              newS[api_sequence.sequence_id+1]={
                item_name:api_sequence.item_name,
                item_id:api_sequence.item_id,
                price:api_sequence.price,
                sequence_id:api_sequence.sequence_id
              }
            }
          })
          setSequence(newS)

        }else{

          // 照預設值來排
          if(proGroup){
            let count = 0
            proGroup.map((modifierGroupId)=>{
              if(modifierGroupId.modifierOptionIds){
                count=count+modifierGroupId.modifierOptionIds.length
              }
            })

            let pageNumber = count>19?Math.ceil((count-19)/20) + 1:1
            let newS = new Array(pageNumber*20).fill({item_name:''})
            newS[19] = {item_name:'單品備註',speical_tag:'單品備註'}
            // newS[18] = {item_name:'折扣',speical_tag:'折扣'}
            // newS[17] = {item_name:'整單加購',speical_tag:'加購'}
      
            let index = 0

            proGroup.map((modifierGroupId,i)=>{

              modifierGroupId.modifierOptionIds.map((modifierOptionId,j)=>{
                // let newS = [...sequence]
                if(index<19){
                  newS[index]={
                    item_id:modifierOptionId.id,
                    item_name:getModifierOptionDetail(modifierOptionId.id).title,
                    group_id:modifierGroupId.id,
                    price:getModifierOptionDetail(modifierOptionId.id).price
                  }
                }else{
                  newS[index+1]={
                    item_id:modifierOptionId.id,
                    item_name:getModifierOptionDetail(modifierOptionId.id).title,
                    group_id:modifierGroupId.id,
                    price:getModifierOptionDetail(modifierOptionId.id).price
                  }
                }
                index = index + 1
                
              })
            })
            setSequence(newS)

          }else{
            let pageNumber = 1
            let newS = new Array(pageNumber*20).fill({item_name:''})
            newS[19] = {item_name:'單品備註',speical_tag:'單品備註'}
            // newS[18] = {item_name:'折扣',speical_tag:'折扣'}
            // newS[17] = {item_name:'整單加購',speical_tag:'加購'}
            setSequence(newS)

          }


        }
      }
    }

    renewSequenceTable()
  },[productState])


 
  useEffect(()=>{
    if(secondLayerModifierState.length>0){
      setModifierOptionMsg(true)
      // setSecondLayerModifierState([])
    }else{
      setModifierOptionMsg(false)
    }
  },[secondLayerModifierState])


  useEffect(()=>{
    console.log('this.orderIndex:',orderIndex)
    if(orderIndex==orders.length-1){
      scrollToBottom()
    }
  },[orderIndex])

  useEffect(()=>{
    if(orders[orderIndex]&&orders[orderIndex].modifiers){
      let newTempGroupIdForRadio = {}
      orders[orderIndex].modifiers.forEach((m)=>{
        if(m.firstModifierInfo){
          newTempGroupIdForRadio[m.firstModifierInfo.group_id] = m.firstModifierInfo.item_id
        }else{
          newTempGroupIdForRadio[m.group_id]=m.item_id
        }
      })
      
      setTempGroupIdForRedio(newTempGroupIdForRadio)
    }
    // 把新點選的餐點，加購顯示在下方
    if(orders[orderIndex]&&orders[orderIndex].id){
      setProductState(orders[orderIndex].id)
    }
  },[orderIndex])

  useEffect(()=>{
    addDiscount()
  },[sumMoney])



  function readJsonFile(){
    fetch('menu.json',{
      headers : { 
        'Content-Type': 'application/json',
        'Accept': 'application/json'
       }
    }).then(function(response){
      console.log(response)
      return response.json();
    })
    .then(function(myJson) {
      setData(myJson)
      let thisMenu = getMenu(myJson.menus)
      setMenu(thisMenu)
      setCategorytate(thisMenu.categoryIds[0].externalId)
      setProductState(thisMenu.categoryIds[0].productIds[0].externalId)
    });
  }

  
  async function connectToDataApi(){

    setLoadingMsg(true)


    console.log('account_id::',account_id_from_session)
    if(!account_id_from_session||account_id_from_session=='counter'){
      setOfflineMsg([true,'找不到商店資訊','請重新登入以讀取商店資訊'])
    }

    // let shop_id = 'P_RLQqpBG9N'
    let shop_id_api = await api.getShopId(account_id_from_session)
    let shop_Name_api = await api.getShopName(account_id_from_session)

    if(shop_Name_api){
      if(shop_Name_api.result&&shop_Name_api.result[0]){
        let shop_name = shop_Name_api.result[0].name
        console.log('shop_name:',shop_name)
        setShopName(shop_name)
      }else{
        setShopName('菜單')
      }
    }else{
      setShopName('菜單')
    }
    
    
    if(shop_id_api){
      if(shop_id_api.result&&shop_id_api.result[0]){
        let shop_id = shop_id_api.result[0].shop_id

        setShopId(shop_id)
      
        // let shop_id = 'D_emoKQekLj'
        console.log('shop_id:::',shop_id)
        let result = await api.menuApi(shop_id)
        if(result){
          setData(result)
          console.log('rrr:',result)
          if(!result.menus){
            setLoadingMsg(false)
            setOfflineMsg([true,'找尋不到菜單','請確認IIOTAPISDK權限/菜單存在，重新登入'])
            // history.push(`/login`);
          }else{

            let pickMenu = getMenu(result.menus)[0]
            let menu_type = getMenu(result.menus)[1]
            console.log('menu_type:',menu_type)
            if(pickMenu&&menu_type!=='none'){
              setMenu(pickMenu)
              if(menu_type=='quickclick'){
                // 綁定此啟用菜單和CO
                console.log('綁定菜單CO')
                await api.bindCOMenu(account_id_from_session)
              }
            }else{
              // 如果published沒有counter，直接撈第一筆菜單，以防出錯
              setMenu(result.menus[0])
            }

            if(pickMenu&&pickMenu.categoryIds&&pickMenu.categoryIds[0]){
                setCategorytate(pickMenu.categoryIds[0].externalId)
                // setProductState(result.menus[0].categoryIds[0].productIds[0].externalId)
                let newS = [...sequence]
                newS[19]={
                  item_name:'單品備註',
                  speical_tag:'單品備註'
                }
                // newS[18]={
                //   item_name:'折扣',
                //   speical_tag:'折扣'
                // }
                // newS[17]={
                //   item_name:'整單加購',
                //   speical_tag:'加購'
                // }
                setSequence(newS)
            }
            setLoadingMsg(false)

          }


        }else{
          setLoadingMsg(false)
          setOfflineMsg([true,'連線不到商店資訊','請確認網路狀態並重新整理'])
        }
      }
    }else{
      setLoadingMsg(false)
      setOfflineMsg([true,'連線不到遠端菜單','請確認網路狀態並重新整理'])
    }
   
  }



  const fullScreen = () => {
      console.log('fullscreen:',isFullScreen);
        if(!isFullScreen){
          requestFullScreen();
        }else{
          exitFullscreen();
        }
      };
    
      const requestFullScreen = () => {
            console.log('requestFullScreen')
            var de = document.documentElement;
            console.log(de, 'deeeeeee')
            if (de.requestFullscreen) {
                de.requestFullscreen();
            } else if (de.mozRequestFullScreen) {
                de.mozRequestFullScreen();
            } else if (de.webkitRequestFullScreen) {
                de.webkitRequestFullScreen();
            }
            setIsFullScreen(true)
        };
    
      const exitFullscreen = () => {
            console.log('exitFullscreen')
    
            if (document.exitFullScreen) {
                document.exitFullScreen();
    
            } else if (document.mozCancelFullScreen) {
                document.mozCancelFullScreen();
    
            } else if (document.webkitExitFullscreen) {
                document.webkitExitFullscreen();
    
            } else if (document.msExitFullscreen) {
                document.msExitFullscreen();
    
            }
            setIsFullScreen(false)
        };
    
      const watchFullScreen=()=>{
            document.addEventListener(
                "webkitfullscreenchange",
                function(){
    //                 _self.setState({
    //                     isFullScreen: document.webkitIsFullScreen
    //                 });
                  setIsFullScreen(document.webkitIsFullScreen)
              },
            false
          );
        };
      


  // useEffect(()=>{
    async function updateTime(){
      var timer = setInterval(()=>setDate(new Date()),5000)
      return function cleanup(){
        clearInterval(timer)
      }
    }
    // })
  
    // useEffect(()=>{
    async function readMenuAndIndexDB(){
      let readMenu=async()=>{
          await connectToDataApi()
          
          // call getPosDvrApi
          console.log("GETPOSDVRAPI")
          const response = await api.getPosDvrApi(account_id_from_session);
          const posDvrConfig = response.result
          console.log("GETPOSDVRAPI")

          window.localStorage.setItem('posDvrConfig', JSON.stringify(posDvrConfig))
          // 取得餐號，存到tables的state
          let tables_object_array = await api.getTableNumbers(account_id_from_session)
          if(tables_object_array&&tables_object_array.result){
            let tables_array = tables_object_array.result.map((TOA)=>{
              return TOA.table_number
            })
  
            if(tables_array&&tables_array.length>0){
              setTables(tables_array)
              setTableNumber(tables_array[0])
            }
          }
          
        
      };
      
      await readMenu()
     
      setTimeout(()=>{
        if(indexedDBCanWork){
          console.log('讀取indexedDB...')
          read(setOrders,db,setSumMoney,setOrderIndex,setProductState)
        }else{
          console.log('讀不到indxedDB...')
        }
      },1000)
  
    //  setTimeout(()=>{
    //     scrollToBottom()
    //  },2000)
  
    
  }
  
  
    function clickCategory(categoryId){

      let {title,externalId} = getCategoryDetail(categoryId)
      addUserLogs('click_category',{title,categoryId,externalId})

      slidePackageSettingImplicit()

      let allRequiredPass = checkIfClickRequired()
      
  
     
      if(allRequiredPass==null||allRequiredPass==true){
        setCategorytate(categoryId)
        let newS = new Array(20).fill({item_name:''})
        newS[19] = {item_name:'單品備註',speical_tag:'單品備註'}
        // newS[18] = {item_name:'折扣',speical_tag:'折扣'}
        // newS[17] = {item_name:'整單加購',speical_tag:'加購'}
        setSequence(newS)
      }else{
        setRequiredMsg(true)
        
      }
  
    }


  function checkIfClickRequired(){
    let allRequiredPass = true

    
    if(orderIndex!==undefined&&orders[orderIndex]){
      console.log('上一個已選取的物件：',orders[orderIndex])

      if(orders[orderIndex].modifierGroupIds){
        orders[orderIndex].modifierGroupIds.map((group)=>{



          if(getModifierDetail(group.id)&&getModifierDetail(group.id).required){
            console.log('第一層必選測試:::',getModifierDetail(group.id).required,orders[orderIndex].modifiers)

            let thisGroupPass = false

            if(orders[orderIndex].modifiers){
              orders[orderIndex].modifiers.forEach((item)=>{
                if(item.group_id===group.id){
                  thisGroupPass = true
                }
                if(item.firstModifierInfo&&item.firstModifierInfo.group_id==group.id){
                  thisGroupPass = true
                }
              })
            }

            if(!thisGroupPass){
              allRequiredPass = false
            }
          }
          
        })
      }
      return allRequiredPass

    }else{
      console.log('無上一個物件：')
      return null
    }

  }

  async function clickProduct(productId,optionID){


    slidePackageSettingImplicit()

    let allRequiredPass = checkIfClickRequired()

    if(allRequiredPass!==null){
      if(allRequiredPass==true){
        // setOrderIndex(orderIndex+1)
        setOrderIndex(orders.length)
      }
    }else{
      console.log('無上一個物件：')

      setOrderIndex(0)
    }

    console.log('allRequiredPass',allRequiredPass)

    if(allRequiredPass==null||allRequiredPass==true){

      setProductState(productId)
      setOptionID(optionID)
      let newOrder = {...getProductDetail(productId)}
      console.log('newOrder:',newOrder)
      newOrder.modifiers=[]
      if(newOrder.defaultValues){
        if(newOrder.defaultValues.selectedModifierGroups&&newOrder.defaultValues.selectedModifierGroups.length>0){
          newOrder.defaultValues.selectedModifierGroups.forEach((g1)=>{
            g1.selectedModifierOptions.forEach((m1)=>{
              let m1Detail = getModifierOptionDetail(m1.id)

              // 如果有第二層
              if(m1.selectedModifierGroups&&m1.selectedModifierGroups.length>0){
                m1.selectedModifierGroups.forEach((g2)=>{
                  g2.selectedModifierOptions.forEach((m2)=>{
                    let m2Detail = getModifierOptionDetail(m2.id)
                    newOrder.modifiers.push({
                      item_id:m2Detail.id,
                      item_name:m2Detail.title,
                      price:m2Detail.price,
                      group_id:g2.id,
                      firstModifierInfo:{
                        item_id:m1Detail.id,
                        item_name:m1Detail.title,
                        price:m1Detail.price,
                        group_id:g1.id
                      }
                    })
                  })
                })
              }else{
                // 只有一層
                newOrder.modifiers.push({
                  item_id:m1Detail.id,
                  item_name:m1Detail.title,
                  price:m1Detail.price,
                  group_id:g1.id,
                })
              }
              
            })
          })
        }
        
      }else{
        // 沒有預選的，讓他預選第一個（但要必選的）
        // 預選先comment
        // console.log('newOrder:::',newOrder)
        // newOrder.modifierGroupIds.forEach((g1)=>{
        //   let g1Detail = getModifierDetail(g1.id)
        //   console.log('g1Detail::',g1Detail)
        //   if(g1Detail.required){
        //     // 若是必選
        //     g1.modifierOptionIds.forEach((m1,i)=>{
        //       let m1Detail = getModifierOptionDetail(m1.id)
              
        //       // 如果有第二層
        //       if(m1Detail.modifierGroupIds&&m1Detail.modifierGroupIds.length>0){
        //         // 第二層的我就不讓他預選，因為還要讓他點選第二層的選項
        //       }else{
        //         // 只有一層
        //         // 選第一個
        //         if(i==0){
        //           newOrder.modifiers.push({
        //             item_id:m1Detail.id,
        //             item_name:m1Detail.title,
        //             price:m1Detail.price,
        //             group_id:g1.id,
        //           })
        //         }
        //       }
              
        //     })
        //   }
        // })
      }

      addUserLogs('click_product',{newOrder})


      setOrders([...orders,newOrder])
      if(indexedDBCanWork){
        storeToIndexedDB([...orders,newOrder])
      }
      addMoney(1*newOrder.price)
    }else{
      setRequiredMsg(true)
      
    }
    // scrollToBottom()

    

  }

  

  function clickModifier(modifierOptionId,group_id,from_where){


    slidePackageSettingImplicit()

    
    if(from_where=='requiredMsg'){
      // setTempClickModifier([modifierOptionId,group_id])
      setRequiredMsg(false)
    }

    // let cancelOriginalItemClicked = false
    console.log(group_id)

    console.log('getModifierDetail(group_id):',getModifierDetail(group_id))

    if(getModifierDetail(group_id).type){
      console.log('tempGroupIdForRadio:',tempGroupIdForRadio)
      console.log('type:::',getModifierDetail(group_id).type)
      if(getModifierDetail(group_id).type=='radio'){
        console.log('group_id:::',group_id)
        if(tempGroupIdForRadio[group_id]){
          // cancelOriginalItemClicked = true
          unClickModifier(tempGroupIdForRadio[group_id],group_id)
        }
        let newTempGroup = {...tempGroupIdForRadio}
        newTempGroup[group_id] = modifierOptionId
        setTempGroupIdForRedio(newTempGroup)
      }
    }

    // if(cancelOriginalItemClicked){
    //   unClickModifier(newTempGroup[group_id],group_id)
    // }

    
  
    let newOption = getModifierOptionDetail(modifierOptionId)

    console.log('newOption:',newOption)

    if(newOption){
      if(newOption.modifierGroupIds){
        // 兩層加購modifierGroupIds
        setModifierState({...newOption,group_id})

        if(newOption.defaultValues){

          let newSecondModifierTemp = {...secondModifierTemp}

          newOption.defaultValues.selectedModifierGroups.forEach((selectedGroup)=>{
            // console.log('test:::',newOption.modifierGroupIds,selectedGroup.selectedModifierOptions[0].id)
            // console.log(newOption.modifierGroupIds.findIndex(x => x.id === selectedGroup.selectedModifierOptions[0].id))
            // console.log(newOption.modifierGroupIds[newOption.modifierGroupIds.findIndex(y=>y.id===group_id)])
            let selectedGroupIndex = newOption.modifierGroupIds.findIndex(x=>x.id===selectedGroup.id)
            selectedGroup.selectedModifierOptions.forEach((selectedOption)=>{
              if(selectedOption.id){
                let newCheckedIndexs = {}
                if(newSecondModifierTemp[selectedGroupIndex]&&newSecondModifierTemp[selectedGroupIndex].checkedIndexs){

                  newCheckedIndexs = {...newSecondModifierTemp[selectedGroupIndex].checkedIndexs}
                //   newCheckedIndexs[newOption.modifierGroupIds[selectedGroupIndex].modifierOptionIds.findIndex(y=>y.id===selectedOption.id)] = true
                //   newSecondModifierTemp[selectedGroupIndex]={
                //     item_id: selectedOption.id,
                //     item_name: getModifierOptionDetail(selectedOption.id).title,
                //     firstModifierInfo: {
                //       item_name:newOption.title,
                //       item_id:newOption.id,
                //       group_id:group_id,
                //       price:newOption.price,
                //     },
                //     price:getModifierOptionDetail(selectedOption.id).price,
                //     checkedIndexs:newCheckedIndexs
                //   }
                // }else{
                //   newSecondModifierTemp[selectedGroupIndex]={
                //     item_id: selectedOption.id,
                //     item_name: getModifierOptionDetail(selectedOption.id).title,
                //     firstModifierInfo: {
                //       item_name:newOption.title,
                //       item_id:newOption.id,
                //       group_id:group_id,
                //       price:newOption.price,
                //     },
                //     price:getModifierOptionDetail(selectedOption.id).price,
                //     checkedIndexs:[newOption.modifierGroupIds[selectedGroupIndex].modifierOptionIds.findIndex(y=>y.id===selectedOption.id)]
                //   }
                // }
                }
                newCheckedIndexs[newOption.modifierGroupIds[selectedGroupIndex].modifierOptionIds.findIndex(y=>y.id===selectedOption.id)] = true
                newSecondModifierTemp[selectedGroupIndex]={
                  item_id: selectedOption.id,
                  item_name: getModifierOptionDetail(selectedOption.id).title,
                  firstModifierInfo: {
                    item_name:newOption.title,
                    item_id:newOption.id,
                    group_id:group_id,
                    price:newOption.price,
                  },
                  price:getModifierOptionDetail(selectedOption.id).price,
                  checkedIndexs:newCheckedIndexs
                }
              }
            })
            // if(selectedGroup.selectedModifierOptions[0].id){
            //   newSecondModifierTemp[selectedGroupIndex]={
            //     item_id: selectedGroup.selectedModifierOptions[0].id,
            //     item_name: getModifierOptionDetail(selectedGroup.selectedModifierOptions[0].id).title,
            //     firstModifierInfo: {
            //       item_name:newOption.title,
            //       item_id:newOption.id,
            //       group_id:group_id,
            //       price:newOption.price,
            //     },
            //     price:getModifierOptionDetail(selectedGroup.selectedModifierOptions[0].id).price,
            //     checked:newOption.modifierGroupIds[selectedGroupIndex].modifierOptionIds.findIndex(y=>y.id===selectedGroup.selectedModifierOptions[0].id)
            //   }
            // }
            
          })
          // setSecondModifierTemp({})
          // newSecondModifierTemp[newOption.defaultValues.selectedModifierGroups.id]={
          //   item_id: option.id,
          //   item_name: option.title,
          //   firstModifierInfo,
          //   // item_name_plus_first_modifier_name:firstModifierName+':'+option.title,
          //   price: option.price,
          //   checked: j,
          // }
          console.log(newSecondModifierTemp)
          setSecondModifierTemp(newSecondModifierTemp)
        }
        
        let secondLayerModifiers = newOption.modifierGroupIds.map((group)=>{
          
          return {
            id: group.id,
            title:getModifierDetail(group.id).title,
            price:getModifierDetail(group.id).price,
            // type:getModifierDetail(group.id).type,
            required: getModifierDetail(group.id).required, //1代表必填 0代表非填
            options: group.modifierOptionIds.map((option)=>{
              return{
                id:option.id,
                title:getModifierOptionDetail(option.id).title,
                price:getModifierOptionDetail(option.id).price,
                group_id:group_id
              }
            })
          }
        })
        
        setSecondLayerModifierState(secondLayerModifiers)
        // setModifierOptionMsg(true)

      }else{
        // 一層加購modifierGroupIds
        newOption = {
          group_id:group_id,
          item_name:newOption.title,
          item_id:newOption.id,
          price:newOption.price,
          type:newOption.type,
        }
        if(newOption&&newOption.modifierGroupIds&&newOption.modifierGroupIds.length>0){
          setVisible(true)
          setModifierState(newOption)
        }
    
        let newOrders = [...orders]

        if(newOrders[orderIndex]){
          if(!newOrders[orderIndex].modifiers){
            newOrders[orderIndex].modifiers=[]
          }

          newOrders[orderIndex].modifiers.push(newOption)

          // 加上加購的錢
          if(!newOrders[orderIndex].count){
            newOrders[orderIndex].count = 1
          }
          newOrders[orderIndex].price=newOrders[orderIndex].price+newOption.price


          addUserLogs('click_modifier',{proudct:newOrders[orderIndex]})

          addMoney(newOption.price*newOrders[orderIndex].count)

          // 存回訂單和indexedDB
          setOrders(newOrders)
          if(indexedDBCanWork){
            storeToIndexedDB(newOrders)
          }
        }
    
        // if(!newOrders[newOrders.length-1].modifiers){
        //   newOrders[newOrders.length-1].modifiers=[]
        // }
        // newOrders[newOrders.length-1].modifiers.push(newOption)
        // console.log('orders:::',newOrders)
        // setOrders([...newOrders])
        // updateIndexedDB(newOrders)
      }
    }

  
  
  }

  function unClickModifier(modifierOptionId,group_id,from_where){

    addUserLogs('unclick_modifier',{modifierOptionId,group_id,from_where})

    console.log('unClickModifier:::',modifierOptionId,group_id)
    let newOrders = [...orders]

    let allMinusMoney = 0
    
    if(orders[orderIndex]&&orders[orderIndex].modifiers){

      let removeIndexed = []
      
      orders[orderIndex].modifiers.forEach((m,j)=>{
        if(m.firstModifierInfo){
          if(modifierOptionId==m.firstModifierInfo.item_id){
            allMinusMoney=allMinusMoney+m.price*(orders[orderIndex].count?orders[orderIndex].count:1)
            orders[orderIndex].price = orders[orderIndex].price - m.price
            removeIndexed.push(j)
            // 如果去掉的是最後一個，把加購品項的第一層價格也刪掉
            if(j==orders[orderIndex].modifiers.length-1){
              orders[orderIndex].price = orders[orderIndex].price - m.firstModifierInfo.price
              allMinusMoney=allMinusMoney+m.firstModifierInfo.price*(orders[orderIndex].count?orders[orderIndex].count:1)
            }
          }
        }else{
          if(modifierOptionId==m.item_id){
            allMinusMoney=allMinusMoney+m.price*(orders[orderIndex].count?orders[orderIndex].count:1)
            orders[orderIndex].price = orders[orderIndex].price - m.price
            orders[orderIndex].modifiers.splice(j, 1)
          }
        }
      })

      for (var i = removeIndexed.length -1; i >= 0; i--){
        orders[orderIndex].modifiers.splice(removeIndexed[i],1);
      }


    }

    setOrders(newOrders)
    if(indexedDBCanWork){
      storeToIndexedDB(newOrders)
    }
    addMoney(-allMinusMoney)
    
    
  }

  function getMenu(menus){
    let thisMenu = {}
    let menu_type = ''
    menus.forEach((menu,i)=>{
      if(menu.published&&menu.published.counter){ //！這邊是&&，只是暫時測試，測試完要改回來
        thisMenu = menu
      }
    })

    
    if(!Object.keys(thisMenu).length){
      // thisMenu = menus[0]
      // 如果沒有，選啟用(is_enabled)的那個菜單
      menus.forEach((menu,i)=>{
        if(menu.published&&menu.published.quickclick){ //！這邊是&&，只是暫時測試，測試完要改回來
          thisMenu = menu
        }
      })

      if(Object.keys(thisMenu).length){
        menu_type = 'quickclick'
      }else{
        menu_type = 'none'
      }
    }else{
      menu_type = 'counter'
    }

    return [thisMenu,menu_type]
  }

  function getCategoryDetail(categoryId){
    if(data.categories){
      return data.categories.filter((category)=>{
        return category.externalId==categoryId
      })[0]
    }
  }

  function getProductDetail(productId){
    if(data.products){
      return data.products.filter((product)=>{
        return product.id==productId
      })[0]
    }
  }

  function getModifierDetail(modifierId){
    if(data.modifierGroups){
      return data.modifierGroups.filter((modifierGroup)=>{
        return modifierGroup.id==modifierId
      })[0]
    } 
  }

  function getModifierOptionDetail(modifierOptionId){
    if(data.modifierOptions){
      return data.modifierOptions.filter((modifierOption)=>{
        return modifierOption.id==modifierOptionId
      })[0]
    }
  }

  function addNumber(orderIndex){

    
    let newOrders = [...orders]
    // let newOrder = newOrders.find((no)=>{
    //   return no.id == orders[orderIndex].id
    // })
    let newOrder = newOrders[orderIndex]


    addUserLogs('add_number',{product:newOrder,newNumber:newOrders[orderIndex].count + 1})


    if(newOrder){
      if(newOrder.count){
        newOrders[orderIndex].count = newOrders[orderIndex].count + 1
      }else{
        newOrders[orderIndex].count = 1 + 1
      }
    }

    setOrders(newOrders)
    if(indexedDBCanWork){
      storeToIndexedDB(newOrders)
    }
    addMoney(newOrder.price)
    
  }

  function minusNumber(orderIndex){
    let newOrders = [...orders]
    // let newOrder = newOrders.find((no)=>{
    //   return no.id == orders[orderIndex].id
    // })

    let newOrder = newOrders[orderIndex]
    let newOrderIndex = orderIndex

    addUserLogs('minus_number',{product:newOrder,newNumber:newOrders[orderIndex].count - 1})


    if(newOrder){
      if(newOrder.count){
        if(newOrder.count>1){
          newOrders[orderIndex].count = newOrders[orderIndex].count - 1
        }else{
          newOrders.splice(orderIndex, 1)
          newOrderIndex = orderIndex-1
        }
      }else{
        if(newOrder.count==1){
          console.log('b1')
          newOrders[orderIndex].count = 1 - 1

        }else{ 
          console.log('b2')
          newOrders.splice(orderIndex, 1)
          newOrderIndex = orderIndex-1
        }
      }

      if(newOrderIndex==-1){
        newOrderIndex = 0
      }
      
      console.log('newOrders:::',newOrders)
      console.log('newOrderIndex:',newOrderIndex)
      addMoney(-newOrder.price)
      setOrders(newOrders)
      // 原本這邊應該是setOrderIndex，但我多做一層，因為要先讓setOrders狀態全部變完，再去更改orderIndex
      // 所以我這邊改成setOrderIndexPre，然後再useeffect中去監聽setOrderIndexPre改變後去更改
      setOrderIndexPre(newOrderIndex)
      // setOrderIndex(newOrderIndex)
      if(indexedDBCanWork){
        storeToIndexedDB(newOrders)
      }
    }
    // setOrders(newOrders)
    // storeToIndexedDB(newOrders)

  }

  useEffect(()=>{
    // console.log('!!!!',orderIndexPre)
    if(orderIndexPre!==-1){
      setOrderIndex(orderIndexPre)
      setOrderIndexPre(-1)
    }
  },[orderIndexPre])

  function handleChangeNumber(){
    console.log('handleChangeNumber')
  }

  function clearOrders(){
    // setCategorytate('')
    // setProductState('')
    // setModifierState('')
    setOrders([])
    setOrderIndex()
    setOrderIndexPre()
    if(indexedDBCanWork){
      removeIndexeddbData()
    }
    setSumMoney(0)
    setDiscountPercentSum(10)
    setDiscountMoneySum(0)
    setDiscountSumWay('money')
    setDiscountWholeWay('manual_whole')
    setProductState('')
    setDiscountMoneySum('')
    setDiscountPercentSum('')
    setDiscountSum(0)
    setRepeatOrder(false)
    setPackageModifier([])
    setPackageNoteMsg(false)
    setModifierOptionMsg(false)
    setPackageNote('')
    setPackageNoteTextArea('')
    setPackageMoney(0)
    setPackageDivGrayAnimationLeft('-25%')
    setPackageDivGrayShow(false)
    setActionLogs([])
    setCarrierId('')
    setTaxId('')
  }

  const addMoney = (number) => setSumMoney((prev) => prev + number)

  const dayToChinese = (dayNumber) => {
    switch(dayNumber){
      case 1:
        return '星期一'
      case 2:
        return '星期二'
      case 3:
        return '星期三'
      case 4:
        return '星期四'
      case 5:
        return '星期五'
      case 6:
        return '星期六'
      case 7:
        return '星期日'
    } 
  }

  const timeToAMPMTIME = (date) => {
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0'+minutes : minutes;
    var strTime = ampm + '  ' + hours + ':' + minutes ;
    return strTime;
  }

  async function payButton(isOnlinePayment){
    let allRequiredPass = checkIfClickRequired()
    // 有無必選的沒選，如果都有選才進到結帳
    console.log('allRequiredPass:::',allRequiredPass)
    if(allRequiredPass==null||allRequiredPass===true){
      console.log("repeatOrder:::",repeatOrder)
      try{
        if(!repeatOrder){
          // 防止快速點，2秒內的訂單只算第一筆
          setRepeatOrder(true)
          setLoadingMsg(true)

          
          let postOrderResult = await postOrderFun(isOnlinePayment)
          console.log('postOrderResult:', postOrderResult)
          if(postOrderResult&&postOrderResult.result){
            setLoadingMsg(false)

            if(postOrderResult.result.orderId){
              let returnActionLogs = [...actionLogs]
              returnActionLogs.push({'send_order':{
                sumMoney,discountSum,packageMoney,
                lastOrders:orders,
                lastAmount:sumMoney-discountSum+packageMoney
              }})
              console.log('傳送log回去:',
                account_id_from_session,
                postOrderResult.result.orderId,
                returnActionLogs,
                sumMoney-discountSum+packageMoney,
                discountSum)
              const postCoActionLog = await api.post_co_action_logs(
                account_id_from_session,
                postOrderResult.result.orderId,
                returnActionLogs,
                sumMoney-discountSum+packageMoney,
                discountSum)

              
            }
            // api.post_co_action_logs(account_id,order_id,actions)

            // 送單明細
            console.log(postOrderResult.result)
            setPostOrderInfo(postOrderResult.result)
            setFinishMsg(true)

            // 1.call getPosDvrApi from api.js
            const posDvrConfig = await api.getPosDvrApi(account_id_from_session);
            const account = posDvrConfig.result.account;
            const password = posDvrConfig.result.password;
            const posDvrIP = posDvrConfig.result.ip;
            const channel = posDvrConfig.result.channel;
            const posDvrIsEnabled = posDvrConfig.result.enabled;

            // const dateTime = new Date().getTime();
            // const currentTimestamp = Math.floor(dateTime / 1000);

            let date = new Date();
            // be aware of VM's local time(UTC) setting 
            const currentTime = date.toLocaleDateString() + date.toLocaleTimeString();
            console.log('START TIMESTAMP');
            console.log(currentTime);
            console.log('END TIMESTAMP');

            if (posDvrIsEnabled === 1) {
              api.loginLocalDvr(account, password, posDvrIP,posDvrIsEnabled).then((res) => {
                return res;
              })
              .then((res) => {
                const authCookie = api.getAuthCookie(account, password, posDvrIP);
                return authCookie;
              })
              .then((res) => {
                const writePosDvrTag = api.writePosDvrTag(posDvrIP, res.authCookie, channel, currentTime);
                console.log(writePosDvrTag);
              })
              .catch(err => {
                console.log(err);
              })
              
            } else {
              console.log("Posdvr not enabled !")
            }

            setTimeout(()=>{
              clearOrders()
              setFinishMsg(false)
              setRepeatOrder(false)
            },2000)
          }else{
            setRepeatOrder(false)
            setLoadingMsg(false)
            setErrorOrderMsg(true)
          }
        }
      }catch(e){
        console.log('e~~~',e)
        if(e.message=="金額小於0，送單失敗"){
          setErrorOrderMsgContent('總計金額需大於零')
        }
        setRepeatOrder(false)
        setLoadingMsg(false)
        setErrorOrderMsg(true)
      }
    }else{
      setRequiredMsg(true)
    }
    
  }

  async function afterFinishOrder(){
    clearOrders()
    setFinishMsg(false)
  }

  async function postOrderFun(isOnlinePayment){
    let carrierIdCorrect = true
    let taxIdCorrect = true

    if(carrierId){
      console.log('carrierId:',carrierId)

      if(carrierId.length!==8){
        carrierIdCorrect = false
      }

      carrierId.split('').forEach((each_char,i)=>{
        if(i==0){
          if(each_char!=='/'){
            carrierIdCorrect = false
          }
        }else{
          let re = /^[A-Za-z0-9+-.]/
          console.log('re.match:',each_char.match(re))
          if(!each_char.match(re)){
            carrierIdCorrect = false
          }
        }
      })
    }

    if(taxId){

      if(taxId.length!==8){
        taxIdCorrect = false
      }

      taxId.split('').forEach((each_char)=>{
        console.log('統編是不是數字：',each_char,isNaN(Number(each_char)))
        if(isNaN(Number(each_char))){
          taxIdCorrect = false
        }
      })
    }

    console.log('carrierIdCorrect:',carrierIdCorrect)
    console.log('taxIdCorrect:',taxIdCorrect)

    if(!carrierIdCorrect){
      setErrorOrderMsgContent('手機載具格式需符合格式[ /+ 7個英數字符號]，例如: /ABC1234')
    }else if(!taxIdCorrect){
      setErrorOrderMsgContent('統一編號格式錯誤[需八碼數字]')
    }else{

      let shop_id = shopId
      let mealType = leftSide=="inside"?"eatIn":"toGo"
      // let amount = discountSumWay==''?sumMoney:discountSumWay=='money'?sumMoney-discountMoneySum:Math.ceil(sumMoney*discountPercentSum*0.1)
      let amount = sumMoney-discountSum+packageMoney
      if(amount<0){
        throw new Error('金額小於0，送單失敗')
      }
      let discounts = []

      let source = 'qc-counter'

      let clientOptions =  { // 其他參數
        "code": "counter", // required, 決定送單類形 (ubereats, foodpanda, counter...)
        "templateId": "", // optional, 決定客制樣板
        "templateOptions": { // 新增template相關參數
          "someKey": "someValue"
        }
      }

      if(discountWholeWay=='manual_whole'){
        discounts.push({
          "amount": discountSum,
          "name": "手動折扣",
          "type": "COUNTER_DISCOUNT"
        })
        // if(discountSumWay=='money'){
        //   discounts.push({
        //     "amount": discountMoneySum,
        //     "name": "手動折扣",
        //     "type": "COUNTER_DISCOUNT"
        //   })
        // }else if(discountSumWay=='percent'){
        //   discounts.push({
        //     "amount": Math.ceil(sumMoney*discountPercentSum*0.1),
        //     "name": "手動折扣",
        //     "type": "COUNTER_DISCOUNT"
        //   })
        // }
      }


      console.log('orders::::',[...orders])


      let combineSameOrder = []
      orders.forEach((order)=>{
        let repeatAll = false
        let repeatIndex = 0
        combineSameOrder.forEach((order_new,i)=>{
          let repeat = false 
          if(order_new.id==order.id){
            repeat = true
            // 如果有商品備註，且商品備註都相同才會合併
            if(order.note!==order_new.note){
              repeat = false
            }
              // }else{
                // repeat = false
              // }
            // }
            if(order.modifiers.length){
              // 如果modifiers長度不同，一定不合併
              if(order.modifiers.length!==order_new.modifiers.length){
                repeat = false
              }else{
                order.modifiers.forEach((modifier)=>{
                  // 判斷一層加購的第一層 / 二層加購的第二層有無相同
                  let findItOrNot = order_new.modifiers.map((new_modifier)=>new_modifier.item_id).indexOf(modifier.item_id)
                  if(findItOrNot==-1){
                    repeat = false
                  }
                  // 如果是二層加購，二層加購的第一層也要相同
                  if(modifier.firstModifierInfo&&modifier.firstModifierInfo.item_id){
                    let findItOrNotFirstModifier = order_new.modifiers.map((new_modifier)=>{
                      if(new_modifier.firstModifierInfo&&new_modifier.firstModifierInfo.item_id){
                        return new_modifier.firstModifierInfo.item_id
                      }
                    }).indexOf(modifier.firstModifierInfo.item_id)
                    if(findItOrNotFirstModifier==-1){
                      repeat = false
                    }
                  }
                })
              }
            }else{
              if(order_new.modifiers.length>0){
                repeat = false
              }
            }

            if(repeat){
              repeatAll = true
              repeatIndex = i
            }
          }
        })
        if(!repeatAll){
          // console.log('o:::',order.title)
          combineSameOrder.push(order)
        }else{
          // console.log('r::',order.title)
          if(combineSameOrder[repeatIndex].count){
            combineSameOrder[repeatIndex].count = combineSameOrder[repeatIndex].count+(order.count?order.count:1)
          }else{
            console.log('~~',combineSameOrder[repeatIndex].count,order.count)
            combineSameOrder[repeatIndex].count = 1+(order.count?order.count:1)
          }
        }
        // console.log('order.title:',order.title)
        // console.log('combin:',combineSameOrder)
      })

      console.log('combineSameOrder::',combineSameOrder)

      setOrders(combineSameOrder)
    
      let carts = combineSameOrder.map((order)=>{


        let newO = {}

        order.modifiers.map((modifier)=>{
          
          if(modifier.firstModifierInfo){
            if(newO[modifier.firstModifierInfo.item_id]){
              newO[modifier.firstModifierInfo.item_id].selectedModifierGroups.push({
                selectedModifierOptions:[{
                  id:modifier.item_id,
                  title:modifier.item_name,
                  price:modifier.price,
                }]
              })
            }else{
              newO[modifier.firstModifierInfo.item_id]={
                id:modifier.firstModifierInfo.item_id,
                title:modifier.firstModifierInfo.item_name,
                price:modifier.firstModifierInfo.price,
                selectedModifierGroups: [
                  {
                    selectedModifierOptions:[{
                      id:modifier.item_id,
                      title:modifier.item_name,
                      price:modifier.price,
                    }]
                  }
                ]
              }
            }
            
          }
        })


        let hasSecondModifierGroups = Object.keys(newO).map((firstModifierId)=>{
          return newO[firstModifierId]
        })


        console.log('hasSecondModifierGroups:',hasSecondModifierGroups)

        let noSecondModifierGroups = []
        order.modifiers.map((modifier)=>{
          if(!modifier.firstModifierInfo){
            noSecondModifierGroups.push({
              id:modifier.item_id,
              title:modifier.item_name,
              price:modifier.price,
            })
          }
        })

        console.log('noSecondModifierGroups:',noSecondModifierGroups)

        let combineModifierGroups = [...hasSecondModifierGroups,...noSecondModifierGroups]


        
      
        return{
          id: order.id,
          quantity: order.count?order.count:1,
          // price: order.price,
          price: getProductDetail(order.id)?getProductDetail(order.id).price:0,
          title: order.title,
          specialInstructions: order.note?order.note:'',
          selectedModifierGroups:[{selectedModifierOptions:combineModifierGroups}]
        }
      })

      console.log('packageModifier:',packageModifier)
      let selectedModifierGroups = Object.keys(packageModifier).map((i)=>{
        let pM = packageModifier[i]
        return {
          id: pM.firstModifierInfo.group_id,
          "selectedModifierOptions": [
            {
              "id": pM.item_id,
              "price": pM.price,
              "title": pM.item_name
            }
          ]
        }
      })
      console.log('selectedModifierGroups:',selectedModifierGroups)
      // let packageSelectedModifierGroups = [
      //   {
      //     "id": 1,
      //     "title": "是否加購購物袋",
      //     "selectedModifierOptions": [
      //       {
      //         "id": 10,
      //         "price": 10,
      //         "title": "整單加購項目"
      //       }
      //     ]
      //   }
      // ]
      let specialInstructions = packageNote


      console.log('order post:::',{shop_id,tableNumber,amount,mealType,carts,discounts,clientOptions,source,selectedModifierGroups,specialInstructions,carrierId,taxId})
      console.log('v-new')
      try{
        let data = await api.postOrder({
          shop_id,
          tableNumber,
          amount,
          mealType,
          carts,
          discounts,
          clientOptions,
          source,
          selectedModifierGroups,
          specialInstructions,
          carrierId,taxId,isOnlinePayment})
        console.log(data)
        return data

      }catch(e){
        console.log('new e:::',e)
        console.log('送單失敗：',e.message)
        if(e.message==='連不到api'){
          setErrorOrderMsgContent('連線不到伺服器')
        }else if(e.message==='沒有網路'){
          setErrorOrderMsgContent('偵測不到網路')
        }else if(e.message&&e.message.length){
          setErrorOrderMsgContent(e.message)
        }
        return e
      }
    }
    
  }

  async function chooseSecondLayerOption(i,option,firstModifierInfo,j){
    // console.log(i,j,option)
    // let newOrders = [...orders]

    console.log('chooseSecondLayerOption::',i,option,firstModifierInfo,j)
    let newSecondModifierTemp = {...secondModifierTemp}
    console.log('secondModifierTemp:::',secondModifierTemp)

    let newCheckedIndexs = {}
    if(newSecondModifierTemp[i]&&typeof newSecondModifierTemp[i].checkedIndexs=='object'){
      console.log('hi::',newSecondModifierTemp[i].checkedIndexs)
      // 這邊應該要判斷是不是group多選(seleted)，如果是多選，下面那行就要有，並且點第二次要能取消
      // newCheckedIndexs = {...newSecondModifierTemp[i].checkedIndexs}
      newCheckedIndexs[j] = true
    }
    newSecondModifierTemp[i]={
      item_id: option.id,
      item_name: option.title,
      firstModifierInfo,
      // item_name_plus_first_modifier_name:firstModifierName+':'+option.title,
      price: option.price,
      checkedIndexs: newCheckedIndexs,
    }

    console.log('newSecondModifierTemp:',newSecondModifierTemp)
    
   


    setSecondModifierTemp(newSecondModifierTemp)
    // if
    // newOrders[orderIndex].modifiers.push()
  }

  async function choosePackageOption(i,option,firstModifierInfo,j){
    console.log('choosePackageOption::',i,option,firstModifierInfo,j)
    let newPackageTemp = {...packageModifier}
    console.log('newPackageTemp:::',newPackageTemp)

    let newCheckedIndexs = {}
    if(newPackageTemp[i]&&typeof newPackageTemp[i].checkedIndexs=='object'){
      console.log('hi::',newPackageTemp[i].checkedIndexs)
      // newCheckedIndexs = {...newSecondModifierTemp[i].checkedIndexs}
      newCheckedIndexs[j] = true
    }
    newPackageTemp[i]={
      item_id: option.id,
      item_name: option.title,
      firstModifierInfo,
      // item_name_plus_first_modifier_name:firstModifierName+':'+option.title,
      price: option.price,
      checkedIndexs: newCheckedIndexs,
    }

    console.log('newPackageTemp:',newPackageTemp)
    
   


    setPackageModifier(newPackageTemp)
  }

  async function addPackageModifier(){

    addUserLogs('add_package_modifier',{packageModifier})
    setPackageModifierMsg(false)
    // setPackageModifier(secondModifierTemp)

    let newPackageMoney = 0
    console.log('packageMoney1:',packageMoney)

    
    Object.keys(packageModifier).forEach((key)=>{
      // newSumMoney = newSumMoney + packageModifier[key].price?packageModifier[key].price:0
      newPackageMoney = newPackageMoney + packageModifier[key].price?packageModifier[key].price:0
    })
    setPackageMoney(newPackageMoney)
    // setSumMoney(newSumMoney)
    console.log('packageMoney2:',newPackageMoney)
    
  }

  async function addSecondLayerModifier(){


    console.log('modifierState:::',modifierState)
    console.log('secondLayerModifierState:::',secondLayerModifierState)

    let requiredPass = true
    secondLayerModifierState.forEach((secondLayModifier,i)=>{
      // console.log(secondLayModifier,secondModifierTemp[i.toString()])
      if(secondLayModifier.required==1&&!secondModifierTemp[i.toString()]){
        requiredPass = false
      }
    })

    if(requiredPass){
      setModifierOptionMsg(false)

      let newOrders = [...orders]
      if(newOrders[orderIndex]){
        if(!newOrders[orderIndex].modifiers){
          newOrders[orderIndex].modifiers = []
        }

        // 全部加總金錢(加購第一層和第二層)
        let addMoneySum = 0

        console.log('secondModifierTemp::',secondModifierTemp)

        if(Object.keys(secondModifierTemp).length == 0){
          // 如果全部選項皆為非必選，不選擇任何選項送出加購，也要能送出（只有一層）
          let newOption = {
            group_id:modifierState.group_id,
            item_name:modifierState.title,
            item_id:modifierState.id,
            price:modifierState.price,
          }
          newOrders[orderIndex].modifiers.push(newOption)
          addMoneySum = addMoneySum + modifierState.price
        }

        Object.keys(secondModifierTemp).map((key,i)=>{
          // 隨便一個加上上一層的price，加一次就好
          if(i==0){
            addMoneySum = addMoneySum + secondModifierTemp[key].firstModifierInfo.price
          }
          if(secondModifierTemp[key].price){
            addMoneySum = addMoneySum + secondModifierTemp[key].price
          }
          newOrders[orderIndex].modifiers.push(secondModifierTemp[key])
        })

        console.log('orders:::',orders)

        // 加上加購第一層和第二層的錢
        if(!newOrders[orderIndex].count){
          newOrders[orderIndex].count = 1
        }
        newOrders[orderIndex].price = newOrders[orderIndex].price + addMoneySum


        addMoney(addMoneySum*newOrders[orderIndex].count)

        addUserLogs('add_secondLayer_modifier',{modifiers:newOrders[orderIndex].modifiers})



        setOrders(newOrders)
        if(indexedDBCanWork){
          storeToIndexedDB(newOrders)
        }



        setSecondModifierTemp({})
        // setSecondLayerModifierState([...secondLayerModifierState])
      }
    }

  }

  async function clickSpeicalTag(food_item,i){
    console.log('clickSpeicalTag',food_item)
    if(food_item.item_name=='單品備註'){
      setNoteMsg(true)
    }
    // if(food_item.item_name=='折扣'){
    //   setDiscountMsg(true)
    // }
    // if(food_item.item_name=='整單加購'){
    //   setPackageModifierMsg(true)

    // }
  }

  async function addNote(){
    setNoteMsg(false)
    setNoteTextArea('')

    if(noteTextArea){
      let newOrders = [...orders]
      newOrders[orderIndex].note = noteTextArea    
      setOrders(newOrders)
      if(indexedDBCanWork){
        storeToIndexedDB(newOrders)
      }
    }
  }

  async function addDiscount(){

    if(discountSumWay=="percent"){
      if(discountPercentSum!==''){
        if(discountPercentSum<=99&&discountPercentSum>=0){
          console.log('test:',discountSumWay,discountPercentSum)

          addUserLogs('add_discount',{discountSumWay,discountPercentSum})

          if(discountPercentSum.toString().length==2){
            // 如果是兩位數88折，就讓他/100，變乘以88/100 = 0.88
            setDiscountSum(Math.round(sumMoney*(1-discountPercentSum*0.01)))
          }else{
            setDiscountSum(Math.round(sumMoney*(1-discountPercentSum*0.1)))
          }
          setDiscountMsg(false)
        }
      }
    }else if(discountSumWay=="money"){
      if(discountMoneySum!==''){
        if(discountMoneySum>=0&&discountMoneySum<=sumMoney){
          console.log('test:',discountSumWay,discountMoneySum)
          addUserLogs('add_discount',{discountSumWay,discountMoneySum})

          setDiscountSum(discountMoneySum)
          setDiscountMsg(false)
        }
      }
    }
  }


  async function sumPercentDiscount(event){
    // // 如果是兩位數88折，就讓他/10，變乘以8.8/10 = 0.88
    // if(event.target.value.length==2&&Number(event.target.value)){
    //   setDiscountPercentSum(Number(event.target.value)/10)
    // }else{
      setDiscountPercentSum(Number(event.target.value))
    // }
  }

  async function sumMoneyDiscount(event){
    setDiscountMoneySum(parseInt(event.target.value))
  }

  let tempGroupId = ''
  let tempModifierColorIndex = 1


  // useEffect(()=>{
  //   scrollToBottom()
  // },[productState])


  const scrollToBottom = () => {
    console.log('scrollToBottom')
    if(bottomDiv){
      bottomDiv.scrollIntoView({ behavior: "smooth" });
    }
  }

  const combineHasFirstModifier = (modifiers) =>{
    let returnStrArr = []

    let tempSecondLayerModifiers = {}
    modifiers.forEach((m)=>{
      if(m.firstModifierInfo){
        if(tempSecondLayerModifiers[m.firstModifierInfo.item_id]){
          tempSecondLayerModifiers[m.firstModifierInfo.item_id].push({
            firstModifierInfo:m.firstModifierInfo,
            item_name:m.item_name,
            price:m.price
          })
        }else{
          tempSecondLayerModifiers[m.firstModifierInfo.item_id] = [{
            firstModifierInfo:m.firstModifierInfo,
            item_name:m.item_name,
            price:m.price
          }]
        }
      }else{
        returnStrArr.push({
          name:m.item_name,
          price:m.price
        })
      }
    })
    Object.keys(tempSecondLayerModifiers).forEach((k)=>{
      
      let combineStr = ''
      combineStr = combineStr + tempSecondLayerModifiers[k][0].firstModifierInfo.item_name 
      if(tempSecondLayerModifiers[k][0].firstModifierInfo.price){
        combineStr = combineStr + '+' + tempSecondLayerModifiers[k][0].firstModifierInfo.price
      }
      combineStr = combineStr + '('


      tempSecondLayerModifiers[k].forEach((sameFirstModifier,i)=>{
        let namePlusPrice = sameFirstModifier.item_name
        if(sameFirstModifier.price){
          namePlusPrice = namePlusPrice + '+' + sameFirstModifier.price
        }
        if(i==tempSecondLayerModifiers[k].length-1){
          combineStr = combineStr + namePlusPrice + ')'
        }else{
          combineStr = combineStr + namePlusPrice + '/'
        }
      })
      
      // return combineStr
      
      returnStrArr.push({
        name:combineStr,
        price:0
      })
    })
    return returnStrArr
  }

  const clickOrder =(i)=>{

    // addUserLogs('clickOrder',{i})

    // 如果點選同一個，不用判斷必選
    if(orderIndex!==i){
      console.log('same orderIndex')

      let allRequiredPass = checkIfClickRequired()
      console.log(allRequiredPass)
      // console.log('allRequiredPass::',allRequiredPass)

      if(allRequiredPass==null||allRequiredPass==true){
        setOrderIndex(i)
        // setProductState(order.id)
        setProductState(orders[i].id)

      }else{
        setRequiredMsg(true)
        
      }
    }else{
      setOrderIndex(i)
      setProductState(orders[i].id)
    }
    
  }

  async function logout(){
    try{
      let result = await api.logoutApi()
      if(result&&result.message=='ok'){
          // history.push(`/counter/${result.account_id}`);
          // console.log('login:::',result.account_id)
          // setAccount_id_from_session(result.account_id)
          clearOrders()
          history.push(`/login`);
      }else{
          console.log('登出失敗')
      }
    }catch(e){
      console.log('登出失敗:',e.stack)
    }
  }


  function slidePackageSettingShow(){
    if(packageDivGrayAnimationLeft=='-25%'){
      let i = -25
      var slideSetting = setInterval(()=>{
        // for(var i=-25;i<=0;i++){
          if(i>=0){
            clearInterval(slideSetting)
          }
          setPackageDivGrayAnimationLeft(`${i}%`)
          i = i + 5
        // }
      },1)
    }
  }

  function slidePackageSettingImplicit(){
    if(packageDivGrayAnimationLeft=='0%'){

      let i = 0
      var slideSetting = setInterval(()=>{
        // for(var i=-25;i<=0;i++){
          if(i<=-25){
            clearInterval(slideSetting)
          }
          
          setPackageDivGrayAnimationLeft(`${i}%`)
          i = i - 5
        // }
      },1)
    }
  }

  // <button class="packageNoteTextButton" onClick={()=>{
  function getNowTimeInputToNoteTextArea(minute){
    var oldDateObj = new Date();
    var newDateObj = new Date();
    newDateObj.setTime(oldDateObj.getTime() + (minute * 60 * 1000));
    let newText = packageNoteTextArea + '取餐時間:' + newDateObj.toTimeString() +'\n'
    setPackageNoteTextArea(newText)
  }

  function addNumberTextToTextarea(number){
    let newText = packageNoteTextArea + number
    setPackageNoteTextArea(newText)
  }

  function addUserLogs(action_name,obj){
    let returnValue = {}
    returnValue[action_name] = {
      ...obj,
      lastOrders:JSON.parse(JSON.stringify(orders)),
      lastAmount:sumMoney-discountSum+packageMoney
    }
    setActionLogs([...actionLogs,returnValue])
  }

  const determineIsDDCInvoiceStore = async(account_id)=>{
    let isDDCInvoiceStore = await api.isDDCInvoiceStoreApi(account_id)
    console.log('isDDCInvoiceStoreResult:',isDDCInvoiceStore)
    if(isDDCInvoiceStore&&isDDCInvoiceStore.result&&isDDCInvoiceStore.result.length){
        if(isDDCInvoiceStore.result[0].is_ddc_invoice==1){
            setIsDDCInvoiceAccount(true)
        }else{
            setIsDDCInvoiceAccount(false)
        }
    }
  }

  const closePlatformSelectMsgFun = () => {
    setPlatformSelectMsg(false)
  }




  return (
    <div>


      <section className="loading_area" style={{"display": "none"}}>
        <div className="loader"></div>
      </section>

      <section className="overlay_for_mobile">
        <div className="attention">本系統須搭配平板使用</div>
      </section>



      <div className="container flex_center">

        <section className="left_bar">
          <div className="year">{date.getYear()+1900}</div>
          <div className="date">{date.getMonth()+1}/{date.getDate()}</div>
          <div className="day">{dayToChinese(date.getDay())}</div>
          <div className="time">{timeToAMPMTIME(date)}</div>
          <div className="tab">
            <a style={{fontSize:'16px'}} src={isFullScreen ? exitFullscreen : fullScreen} alt="" onClick={fullScreen} >{isFullScreen?'切換視窗':'全螢幕'}</a>

            <a onClick={()=>{
              setLeftSide('inside')
              setOrdersShow(false)
            }} className={leftSide=="inside"?"current":""}><i className="fas fa-chair"></i><br/>內用</a>
              <select className="table_number" onChange={(choice)=>{
                setTableNumber(tables[choice.target.options.selectedIndex])
              }}>
                {tables.map((t,i)=>{
                  return  <option value={t} key={i}>桌號{t}</option>
                })}
                {/*<option>桌號1</option>
                <option>桌號2</option>
                <option>桌號3</option>
                <option>桌號4</option>
                <option>桌號5</option>
                */}
              </select>



            <a onClick={()=>{
              setLeftSide('outside')
              setOrdersShow(false)
            }} className={leftSide=="outside"?"current":""}><i className="fas fa-shopping-bag"></i><br/>外帶</a>
            <a style={{padding:"10px 0"}} onClick={()=>{
              setOrdersShow(true)
              setLeftSide('')
              if(ordersShow==true){
                console.log(123)
                setPlatformSelectMsg(true)
              }
            }} className={ordersShow==true?"current":""}>
              <div>
                {ordersShow==true?
                <div style={{backgroundColor:'#FFFFFF',color:'black',borderRadius:'5px',fontFamily:'Noto Sans TC',margin:'5px',fontSize:'12px'}}>
                  訂單平台
                </div>:''}
              </div>
              <i className="fas fa-clipboard-list"></i><br/>訂單列表<br/>{ordersShow?'':'（24hr）'}
            </a>

            <div style={{position:'absolute',bottom:'100px',color:'gray',fontSize:'16px',left:0,right:0}}>網路速度<br/>{wifiSpeed}/mbps</div>

          </div>
          {/* <div className="logo"><img src={logoutImage}/>登出</div> */}
          <div className="logo">
            <a style={{color:'#b3aeae',fontSize:'19px'}} onClick={()=>logout()} ><i className="fas fa-sign-out-alt"></i><br/>登出</a>
          </div>
        </section>

        <section  style={ordersShow?{display:'none'}:{display:'block'}} className="menu_category">
          <div className="title">分類</div>
          <div className="category">
            {menu&&menu.categoryIds?menu.categoryIds.map((category,i)=>{
              return <a key={i} className={categorytate==category.externalId?'current':''} onClick={()=>clickCategory(category.externalId)}>{getCategoryDetail(category.externalId).title}</a>
            }):''}
           
          </div>
        </section>

        <section  style={ordersShow?{display:'none'}:{display:'block'}} className="main_menu">
          <div className="product">
            <div className="title">
              {shopName} 
              <button onClick={()=>{
                // window.location.reload(true);
                history.go(0);

              }} style={{
                  backgroundColor: "white",
                  width: "30px"
              }}>
                <i className="fas fa-sync"></i>
              </button>
            </div>
            
            {/* <div className="flex_center flex_wrap frame"> */}
            <div className="flex_center flex_wrap">
                  {
                    menu&&menu.categoryIds?
                        menu.categoryIds.filter((c)=>{
                          return c.externalId==categorytate
                        })[0]?menu.categoryIds.filter((c)=>{
                          return c.externalId==categorytate
                        })[0].productIds.map((productId,i)=>{
                          if(i%3==2){
                            return (
                                
                                <a className={productState==productId.id?"current":""} style={{flex:1,height:'105px',display:'flex',flexDirection:'column',justifyContent:'space-between'}} key={i} onClick={()=>clickProduct(productId.id,getProductDetail(productId.id).modifierSettingsId)}>
                                  {getProductDetail(productId.id).title}
                                  <span  className="price">$<b>{getProductDetail(productId.id).price}</b></span>
                                </a>
                            )
                          }else{
                            return (
                              <a className={productState==productId.id?"current":""} style={{height:'105px',display:'flex',flexDirection:'column',justifyContent:'space-between'}} key={i} onClick={()=>clickProduct(productId.id,getProductDetail(productId.id).modifierSettingsId)}>
                                {getProductDetail(productId.id).title}
                                <span  className="price">$<b>{getProductDetail(productId.id).price}</b></span>
                              </a>
                            )
                          }
                        }):''
                    :''
                  }
              
            </div>
          </div>

          <div className="add_option">
            <div className="swiper-container">
            <Swiper
                // spaceBetween={50}
                // slidesPerView={3}
                allowTouchMove={false}
                pagination={{ 
                  el: '.swiper-pagination',
                  clickable: true,
                  renderBullet: function (index, className) {
                    return '<span class="' + className + '">' + (index + 1) + '</span>';
                  },
                }}
                // scrollbar={{ draggable: true }}
                onSwiper={(swiper) => {
                  console.log(swiper)
                  setSwiper(swiper)
                }}
                // onSlideChange={() => console.log('slide change')}
              >
              <div className="swiper-wrapper">
                
                <SwiperSlide>
                  <div className="swiper-slide">
                        {
                        sequence.map((food_item,i)=>{
                        // 判斷第一頁
                        if(i<20){
                          // 如果是備註or折扣
                          if(food_item.speical_tag){  
                            return(
                              <a key={i} className="fixed_item" rel="modal:open" type="dashed" onClick={()=>clickSpeicalTag(food_item,i)}>
                                {food_item.item_name}
                              </a>
                            )
                          }else{
                            
                            if(food_item.item_name){
                              // 如果是有內容的
                              let hasClick = false
                              if(orders[orderIndex]&&orders[orderIndex].modifiers){
                                // 去判斷是不是點過的
                                orders[orderIndex].modifiers.forEach((m)=>{
                                  if(m.firstModifierInfo){
                                    if(m.firstModifierInfo.item_id==food_item.item_id){
                                      hasClick = true
                                    }
                                  }else{
                                    if(m.item_id==food_item.item_id){
                                      hasClick = true
                                    }
                                  }
                                })
                              }
                              if(!hasClick){
                                // 如果是沒點過的，接下來判斷是不是同一個顏色
                                if(tempGroupId==food_item.group_id){
                                  return(
                                    // <a key={i}  style={{background:'white',color:'black'}} href="#food_items" rel="modal:open" type="dashed" onClick={()=>clickModifier(food_item.item_id,food_item.group_id)}>
                                    //      {getModifierDetail(food_item.group_id).required?'*':''}
                                    //      {/* {getModifierDetail(food_item.group_id).type=='radio'?'單選':getModifierDetail(food_item.group_id).type=='selectboxes'?'多選':''} */}
                                    //      {food_item.item_name}
                                    // </a>
                                    <a key={i}  style={{background:modifierColor[tempModifierColorIndex],border:''}}  rel="modal:open" type="dashed" onClick={()=>clickModifier(food_item.item_id,food_item.group_id)}>
                                      {/* {getModifierDetail(food_item.group_id).required?'*':''} */}
                                      {food_item.item_name}{food_item.price==0?'':food_item.price>0?'+'+food_item.price:food_item.price}
                                    </a>
                                  )
                                }else{
                                  tempGroupId=food_item.group_id
                                  // tempModifierColorIndex=tempModifierColorIndex==1?0:1
                                  tempModifierColorIndex=tempModifierColorIndex+1
                                  return(
                                    <a key={i}  style={{background:modifierColor[tempModifierColorIndex],border:''}}  rel="modal:open" type="dashed" onClick={()=>clickModifier(food_item.item_id,food_item.group_id)}>
                                      {food_item.item_name}{food_item.price==0?'':food_item.price>0?'+'+food_item.price:food_item.price}
                                    </a>
                                  )
                                }
                                // return(
                                //   <a key={i}  style={{background:'white',color:'black'}} href="#food_items" rel="modal:open" type="dashed" onClick={()=>clickModifier(food_item.item_id,food_item.group_id)}>
                                //     {getModifierDetail(food_item.group_id).required?'*':''}
                                //     {/* {getModifierDetail(food_item.group_id).type=='radio'?'單選':getModifierDetail(food_item.group_id).type=='selectboxes'?'多選':''} */}
                                //     {food_item.item_name}
                                //     {/* ({getModifierDetail(food_item.group_id).title}) */}
                                //   </a>
                                // )
                              }else{
                                // 如果是點過的，接下來判斷是不是同一個顏色
                                if(tempGroupId==food_item.group_id){
                                  return(
                                    <a key={i}  style={{background:modifierColor[tempModifierColorIndex],padding:'2px',border:'4px solid #f0941d'}} rel="modal:open" type="dashed" onClick={()=>unClickModifier(food_item.item_id,food_item.group_id)}>
                                      {food_item.item_name}{food_item.price==0?'':food_item.price>0?'+'+food_item.price:food_item.price}
                                    </a>
                                  )
                                }else{
                                  tempGroupId=food_item.group_id
                                  tempModifierColorIndex=tempModifierColorIndex+1
                                  return(
                                    <a key={i}  style={{background:modifierColor[tempModifierColorIndex],padding:'2px',border:'4px solid #f0941d'}}  rel="modal:open" type="dashed" onClick={()=>unClickModifier(food_item.item_id,food_item.group_id)}>
                                      {food_item.item_name}{food_item.price==0?'':food_item.price>0?'+'+food_item.price:food_item.price}
                                    </a>
                                  )
                                }
                                // tempGroupId=food_item.group_id
                                // tempModifierColorIndex=tempModifierColorIndex==1?0:1
                                // return(
                                //   <a key={i}  style={{background:'#f0941d',color:'#FFF'}} href="#food_items" rel="modal:open" type="dashed" onClick={()=>unClickModifier(food_item.item_id,food_item.group_id)}>
                                //      {/* {getModifierDetail(food_item.group_id).required?'*':''} */}
                                //     {/* {getModifierDetail(food_item.group_id).type=='radio'?'單選':getModifierDetail(food_item.group_id).type=='selectboxes'?'多選':''} */}
                                //     {food_item.item_name}
                                //     {/* ({getModifierDetail(food_item.group_id).title}) */}
                                //   </a>
                                // )
                              }
                             
                            }else{
                              // 如果是無內容的
                              return(
                                <a key={i}  rel="modal:open" type="dashed">
                                  {food_item.item_name}
                                </a>
                              )
                            }
                          }
                        }
                        
                        
                      })
                      
                                      
                    }
                    
                  </div>
                  </SwiperSlide>
                    
                  {sequence.length>20?new Array(Math.floor((sequence.length-20)/20)).fill(false).map((nothing,j)=>{
                    return (
                    <SwiperSlide>
                      <div className="swiper-slide">
                        {
                          sequence.map((food_item,i)=>{
                            if(i>=20*(j+1)&&i<(20+(j+1)*20)){
                              // return(
                                // <a key={i} rel="modal:open" type="dashed" onClick={()=>clickModifier(food_item.item_id,food_item.group_id)}>
                                //   {food_item.item_name}
                                // </a>
                              // )
                              // 如果是備註or折扣
                              if(food_item.speical_tag){                          
                                return(
                                  <a key={i} className="fixed_item" rel="modal:open" type="dashed" onClick={()=>clickSpeicalTag(food_item,i)}>
                                    {food_item.item_name}
                                  </a>
                                )
                              }else{
                                
                                if(food_item.item_name){
                                  // 如果是有內容的
                                  let hasClick = false
                                  if(orders[orderIndex]&&orders[orderIndex].modifiers){
                                    // 去判斷是不是點過的
                                    orders[orderIndex].modifiers.forEach((m)=>{
                                      if(m.firstModifierInfo){
                                        if(m.firstModifierInfo.item_id==food_item.item_id){
                                          hasClick = true
                                        }
                                      }else{
                                        if(m.item_id==food_item.item_id){
                                          hasClick = true
                                        }
                                      }
                                    })
                                  }
                                  if(!hasClick){
                                    // 如果是沒點過的，接下來判斷是不是同一個顏色
                                    if(tempGroupId==food_item.group_id){
                                      return(
                                        // <a key={i}  style={{background:'white',color:'black'}} href="#food_items" rel="modal:open" type="dashed" onClick={()=>clickModifier(food_item.item_id,food_item.group_id)}>
                                        //      {getModifierDetail(food_item.group_id).required?'*':''}
                                        //      {/* {getModifierDetail(food_item.group_id).type=='radio'?'單選':getModifierDetail(food_item.group_id).type=='selectboxes'?'多選':''} */}
                                        //      {food_item.item_name}
                                        // </a>
                                        <a key={i}  style={{background:modifierColor[tempModifierColorIndex],border:''}}  rel="modal:open" type="dashed" onClick={()=>clickModifier(food_item.item_id,food_item.group_id)}>
                                          {/* {getModifierDetail(food_item.group_id).required?'*':''} */}
                                          {food_item.item_name}{food_item.price==0?'':food_item.price>0?'+'+food_item.price:food_item.price}
                                        </a>
                                      )
                                    }else{
                                      tempGroupId=food_item.group_id
                                      // tempModifierColorIndex=tempModifierColorIndex==1?0:1
                                      tempModifierColorIndex=tempModifierColorIndex+1
                                      return(
                                        <a key={i}  style={{background:modifierColor[tempModifierColorIndex],border:''}}  rel="modal:open" type="dashed" onClick={()=>clickModifier(food_item.item_id,food_item.group_id)}>
                                          {food_item.item_name}{food_item.price==0?'':food_item.price>0?'+'+food_item.price:food_item.price}
                                        </a>
                                      )
                                    }
                                    // return(
                                    //   <a key={i}  style={{background:'white',color:'black'}} href="#food_items" rel="modal:open" type="dashed" onClick={()=>clickModifier(food_item.item_id,food_item.group_id)}>
                                    //     {getModifierDetail(food_item.group_id).required?'*':''}
                                    //     {/* {getModifierDetail(food_item.group_id).type=='radio'?'單選':getModifierDetail(food_item.group_id).type=='selectboxes'?'多選':''} */}
                                    //     {food_item.item_name}
                                    //     {/* ({getModifierDetail(food_item.group_id).title}) */}
                                    //   </a>
                                    // )
                                  }else{
                                    // 如果是點過的，接下來判斷是不是同一個顏色
                                    if(tempGroupId==food_item.group_id){
                                      return(
                                        <a key={i}  style={{background:modifierColor[tempModifierColorIndex],padding:'2px',border:'4px solid #f0941d'}} rel="modal:open" type="dashed" onClick={()=>unClickModifier(food_item.item_id,food_item.group_id)}>
                                          {food_item.item_name}{food_item.price==0?'':food_item.price>0?'+'+food_item.price:food_item.price}
                                        </a>
                                      )
                                    }else{
                                      tempGroupId=food_item.group_id
                                      tempModifierColorIndex=tempModifierColorIndex+1
                                      return(
                                        <a key={i}  style={{background:modifierColor[tempModifierColorIndex],padding:'2px',border:'4px solid #f0941d'}}  rel="modal:open" type="dashed" onClick={()=>unClickModifier(food_item.item_id,food_item.group_id)}>
                                          {food_item.item_name}{food_item.price==0?'':food_item.price>0?'+'+food_item.price:food_item.price}
                                        </a>
                                      )
                                    }
                                    // tempGroupId=food_item.group_id
                                    // tempModifierColorIndex=tempModifierColorIndex==1?0:1
                                    // return(
                                    //   <a key={i}  style={{background:'#f0941d',color:'#FFF'}} href="#food_items" rel="modal:open" type="dashed" onClick={()=>unClickModifier(food_item.item_id,food_item.group_id)}>
                                    //      {/* {getModifierDetail(food_item.group_id).required?'*':''} */}
                                    //     {/* {getModifierDetail(food_item.group_id).type=='radio'?'單選':getModifierDetail(food_item.group_id).type=='selectboxes'?'多選':''} */}
                                    //     {food_item.item_name}
                                    //     {/* ({getModifierDetail(food_item.group_id).title}) */}
                                    //   </a>
                                    // )
                                  }
                                
                                }else{
                                  // 如果是無內容的
                                  return(
                                    <a key={i}  rel="modal:open" type="dashed">
                                      {food_item.item_name}
                                    </a>
                                  )
                                }
                              }
                              
                            }
                          })
                            
                            
                          
                      }
                      </div>
                    </SwiperSlide>
                    )
                  }):''}
                  
                </div>
              
                <div className="swiper-pagination"></div>
          
                
              </Swiper>
            </div>
          </div>
        </section>

        <section  style={ordersShow?{display:'none'}:{display:'block'}} className="order_cart">
          <div style={{display:'flex',justifyContent:'space-around',alignItems:'center',padding:'0px'}} className="title">
            <div style={{padding:'10px',width:'50%'}} onClick={()=>{
              slidePackageSettingImplicit()
            }}>已點</div>
            <div onClick={()=>{
              console.log('點擊整單設定')
              if(packageDivGrayShow){
                setPackageDivGrayShow(false)
              }else{
                setPackageDivGrayShow(true)
              }
            }} style={{
              fontSize:'14px',backgroundColor:'#CB5A4B',borderRadius: '5px',padding: '19px',paddingLeft:'25px',paddingRight:'25px',color:'white',height:'29px',display:'flex',alignItems:'center'
            }}>整單設定</div>

          </div>



            
          <div style={{height:'50%'}}>
            <div className="delete_all"><a  onClick={clearOrders}  id="delete_all">清空餐點</a></div>
            <div className="cart">
            {
            orders.map((order,i)=>{
              // addMoney(order.price)
              if(order){
                return(<a onClick={()=>clickOrder(i)} key={i} className={i==orderIndex?'current':''}>
                    <div className="name">{order.title}</div>
                    {
                      combineHasFirstModifier(order.modifiers)?
                      <div className="options">{combineHasFirstModifier(order.modifiers).map((s,j)=>{
                        return (<i key={j}>{s.name}
                        {/* {s.price?'+'+s.price:''} */}
                        {s.price==0?'':s.price>0?'+'+s.price:s.price}
                        </i>)
                      })}</div>:''
                    }
                  
                    {/* {
                      order.modifiers?
                      <div className="options">{order.modifiers.map((modifier,j)=>{
                        // console.log(modifier.firstModifierInfo.item_name)
                        if(modifier&&modifier.item_name){
                          if(modifier.firstModifierInfo){
                          
                            return (<i key={j}>{modifier.item_name}({modifier.firstModifierInfo.item_name})</i>)
                          }else{
                            return (<i key={j}>{modifier.item_name}</i>)
                          }
                        }
                      })}</div>:''
                    } */}
                    {
                      order.note?
                        <div className="options">
                          <i>{order.note}</i>
                        </div>:''
                    }
                    <div className="subtotal flex_center">
                      <div className="input_quantity flex_center">
                        <button onClick={()=>minusNumber(i)} className='plus_and_minus'><i className="fas fa-minus"></i></button>
                        <input value={order.count?order.count:1} onChange={handleChangeNumber} type="number" />
                        <button onClick={()=>addNumber(i)} className='plus_and_minus'><i className="fas fa-plus"></i></button>
                      </div>
                      <div className="price">
                        {/* <del>${order.count?order.count*(order.price+30):(order.price+30)}</del>  */}
                        $<b>{order.count?order.count*(order.price):(order.price)}</b>
                      </div>
                    </div>
                  </a>)
                
              }else{
                console.log('error order::',order)
              }
            })
            }


            <div style={{ height:'10px',float:"left", clear: "both"}}
              ref={(el)=>{bottomDiv=el}}>
            </div>
            
            
            
            </div>

           
        
          </div>

          {/* 滑動出來的視窗 */}
          <div style={{position:'fixed',top:'46px',right:packageDivGrayAnimationLeft,width:'25%',height:'46%',overflowY:'auto',backgroundColor:'white'}}>
             <div style={{display:'flex',justifyContent:'center','align-items':'center',flexDirection:'column'}}>
                
                <a style={{
                  height:'66px',
                  width:'90%',
                  display:'flex',
                  justifyContent:'space-between',
                  alignItems:'center',
                  backgroundColor:'#CB5A4B',
                  color:'white',
                  padding:'10px',
                  margin:'5px 10px'
                }} onClick={()=>setPackageModifierMsg(true)} rel="modal:open">
                  <div>
                    {/* icon */}
                    <i className="fas fa-plus-circle"></i>
                    {/* title */}
                    <a style={{marginLeft:'5px',color:'white'}}>訂單加購</a>
                  </div>
                  <div>
                    {/* 訂購幾個 */}
                    {Object.keys(packageModifier).length?<a style={{
                      // color:'#CB5A4B',
                      color:'white'
                      // backgroundColor:'white',
                      // // padding: '1px',
                      // width:'20px',
                      // height:'20px',
                      // padding:'5px',
                      // borderRadius:'99px',
                      // display:'flex',
                      // justifyContent:'center',
                      // alignItems:'center',
                      // marginRight:'5px'
                    }}>{Object.keys(packageModifier).length}</a>:''}
                  </div>
                </a>
                
                <a  style={{
                  height:'66px',
                  width:'90%',
                  display:'flex',
                  justifyContent:'space-between',
                  alignItems:'center',
                  backgroundColor:'#CB5A4B',
                  color:'white',
                  padding:'10px',
                  margin:'5px 10px'
                }} onClick={()=>setPackageNoteMsg(true)} rel="modal:open">
                  
                  <div>
                    {/* icon */}
                    <i className="fas fa-sticky-note"></i>
                    {/* title */}
                    <a style={{marginLeft:'5px',color:'white'}}>訂單備註</a>
                  </div>
                  <div>
                    {packageNote.length?<i className="fa fa-check"></i>:''}
                  </div>
                </a>
                
                <a onClick={()=>setDiscountMsg(true)} style={{
                   height:'66px',
                   width:'90%',
                   display:'flex',
                   justifyContent:'space-between',
                   alignItems:'center',
                   backgroundColor:'#CB5A4B',
                   color:'white',
                   padding:'10px',
                   margin:'5px 10px'
                }}>
                  <div>
                    {/* icon */}
                    <i className="fas fa-tags"></i>
                    {/* title */}
                    <a style={{marginLeft:'5px',color:'white'}}>訂單折扣</a>
                  </div>
                  <div>
                    {discountSum?'-$'+discountSum:''}
                  </div>
                </a>

                <a style={{
                   height:'66px',
                   width:'90%',
                   display:'flex',
                   justifyContent:'space-between',
                   alignItems:'center',
                   backgroundColor:'#CB5A4B',
                   color:'white',
                   padding:'10px',
                   margin:'5px 10px',
                   display: isDDCInvoiceAccount?'flex':'none'
                }}>
                  <div>
                    {/* icon */}
                    <i className="fas fa-mobile"></i>
                    {/* title */}
                    <a style={{marginLeft:'5px',color:'white'}}>手機載具</a>
                  </div>
                  <input style={{
                    paddingLeft:'8px',
                    backgroundColor:'white',
                    width:'55%',
                    color:'black',
                    height:'80%',
                  }} placeholder="載具ID" onChange={(event)=>setCarrierId(event.target.value.replace(/(^\s*)|(\s*$)/g, ""))} value={carrierId}>
                   
                  </input>
                </a>

                <a style={{
                   height:'66px',
                   width:'90%',
                   display:'flex',
                   justifyContent:'space-between',
                   alignItems:'center',
                   backgroundColor:'#CB5A4B',
                   color:'white',
                   padding:'10px',
                   margin:'5px 10px',
                   display: isDDCInvoiceAccount?'flex':'none'
                }}>
                  <div>
                    {/* icon */}
                    <i className="fas fa-receipt"></i>
                    {/* title */}
                    <a style={{marginLeft:'5px',color:'white'}}>統一編號</a>
                  </div>
                  <input style={{
                      backgroundColor:'white',
                      width:'55%',
                      color:'black',
                      height:'80%',
                      paddingLeft:'8px',
                    }} placeholder="統編8碼" onChange={(event)=>setTaxId(event.target.value)} value={taxId}>
                      
                  </input>
                </a>
                
            </div>
          
          </div>

        

          {/* 為了讓已點菜單置底 */}
          
            
          
          {/* <div style={Object.keys(packageModifier).length?{display:'block',padding:'15px',border:'2px #CB5A4B dashed',marginBottom:'-15px'}:{display:'none'}}>
            <a>
              <div style={{color:'#CB5A4B',paddingBottom:'5px'}}>整單加購</div>
              <div>

              {Object.keys(packageModifier).map((key,i)=>{

                return(
                  <i style={{
                    display:'inline-block',
                    padding:'2px 5px',
                    margin:'0 3px 3px 0',
                    fontSize:'13px',
                    fontStyle:'normal',
                    color:'#979797',
                    border:'1px solid #C4C4C4',
                    borderRadius:'25px'}} key={i}>
                      {packageModifier[key].item_name}
                      {packageModifier[key].price==0?'':packageModifier[key].price>0?'+'+packageModifier[key].price:packageModifier[key].price}
                    </i>
                )
              })}
              </div>
            </a>
          </div> */}
          {/* 這邊是為了讓結帳按鈕往上移，壓縮上面的div，所以一但結束要把下面刪掉，並把cart的height改為60% */}
          {/* <div style={{height:'10%'}}>

          </div> */}


            {/* <div style={{display:'flex',justifyContent:'center','align-items':'center'}}>
              <a style={{
                width:'50%',
                display:'flex',
                justifyContent:'center',
                alignItems:'center',
                backgroundColor:'#CB5A4B',
                color:'white',
                padding:'10px',
                marginRight:'1px',
                marginTop:'1px'
              }} onClick={()=>setPackageModifierMsg(true)} rel="modal:open">
                {Object.keys(packageModifier).length?<a style={{
                  color:'#CB5A4B',
                  backgroundColor:'white',
                  // padding: '1px',
                  width:'20px',
                  height:'20px',
                  padding:'5px',
                  borderRadius:'99px',
                  display:'flex',
                  justifyContent:'center',
                  alignItems:'center',
                  marginRight:'5px'
                }}>{Object.keys(packageModifier).length}</a>:''}
                <a style={{marginLeft:'5px',color:'white'}}>整單加購</a>
              </a>
              
              <a  style={{
                width:'50%',
                display:'flex',
                justifyContent:'center',
                alignItems:'center',
                backgroundColor:'#CB5A4B',
                color:'white',
                padding:'10px',
                marginTop:'1px'

              }} onClick={()=>setPackageNoteMsg(true)} rel="modal:open">
                {packageNote.length?<img style={{width:'20px'}} src={vectorImg}/>:''}
                
                <a style={{marginLeft:'5px',color:'white'}}>整單備註</a>
              </a>
            </div> */}
          <div className="total">

            <a style={{width:'100%',bottom:'calc(100% + 60px)',backgroundColor:'#CB5A4B'}} onClick={()=>payButton(false)} className="submit" rel="modal:open"><i className="fas fa-dollar-sign"></i> 現金結帳</a>
            <a style={{width:'100%',backgroundColor:'#F0941D'}} onClick={()=>payButton(true)} className="submit" rel="modal:open"><i className="fas fa-mobile"></i> 行動支付</a>

            <div className="flex_center flex_wrap">

              <div className="flex_center">
                <span>小計</span>
                <span>$<b>{sumMoney}</b></span>
              </div>

              <div className="flex_center">
                <span>訂單折扣</span>
                {/* {console.log(Math.floor(sumMoney*(1-discountPercentSum*0.1))} */}
                {/* <span className="discount">-$<b>{discountSumWay==''?0:discountSumWay=='money'?discountMoneySum==''?0:discountMoneySum:Math.floor(sumMoney*(1-discountPercentSum*0.1))}</b></span> */}
                <span className="discount">-$<b>{discountSum}</b></span>

              </div>
              <div className="flex_center">
                <span>訂單加購</span>
                <span>$<b>{packageMoney}</b></span>
              </div>

              <div style={{marginTop:'5px',fontWeight:'bold'}} className="flex_center">
                <span>總計</span>
                <span>$<b>{sumMoney-discountSum+packageMoney}</b></span>
              </div>
            </div>
            


          </div>
        </section>

        <section style={ordersShow?{display:'block',width:'100%'}:{display:'none'}}>
          <OrderComponent closePlatformSelectMsgFun={closePlatformSelectMsgFun} platformSelectMsg={platformSelectMsg} ordersShow={ordersShow} account_id={account_id_from_session} user_info={user_info}/>

        </section>
      </div>



      <div className="jquery-model blocker current" style={{display:packageModifierMsg?'block':'none'}}>
        <div id="food_items" className="modal food_items" style={{display:'inline-block'}}>
          <div className="crumbs">整單加購</div>
          {menu&&menu.modifierGroupIds&&menu.modifierGroupIds.length?menu.modifierGroupIds.map((g1,i)=>{
            let g1Detail = getModifierDetail(g1.id)
            return(
              <div key={i}>
                <div className="title">
                  <span className="must">{g1Detail.required?'必選':''}</span>請選擇{g1Detail.title}<i className="fas fa-caret-down"></i>
                  <div className="option_list">
                    {
                      
                    g1.modifierOptionIds&&g1.modifierOptionIds.length?g1.modifierOptionIds.map((o1,j)=>{
                      let o1Detail = getModifierOptionDetail(o1.id)
                      // console.log('o1Detail::',o1Detail)
                      return(
                        <div key={j} onClick={()=>choosePackageOption(i,o1Detail,{
                          item_name:o1Detail.title,
                          item_id:o1Detail.id,
                          price:o1Detail.price,
                          group_id:g1.id
                          },j)} className="the_radio">
                          <input checked={packageModifier[i]?typeof packageModifier[i].checkedIndexs=='object'?packageModifier[i].checkedIndexs[j]?packageModifier[i].checkedIndexs[j]:false:false:false}  type="radio" name={'b'+i} id={'b'+i+j} value={o1Detail.title}/>  
                          <label htmlFor={'b'+i+j}>{o1Detail.title}{o1Detail.price==0?'':o1Detail.price>0?'+'+o1Detail.price:o1Detail.price}</label> 

                        </div>
                      )     
                    })
                    :''}
                  </div>
                </div>
              </div>
            )
          }):'無整單加購選項'}
         
          <div className="sys_btn flex_center">
            <a onClick={addPackageModifier} className="confirm" rel="modal:close">選好了</a>
          </div>
        </div>
      </div>


      <div className="jquery-model blocker current" style={{display:modifierOptionMsg?'block':'none'}}>
        <div id="food_items" className="modal food_items" style={{display:'inline-block'}}>
          <div className="crumbs">{categorytate&&getCategoryDetail(categorytate)?getCategoryDetail(categorytate).title:''} > {productState&getProductDetail(productState)?getProductDetail(productState).title:''} + {modifierState&&modifierState.title?modifierState.title:''}</div>
          {secondLayerModifierState.map((secondLayModifier,k)=>{

            return(
            <div key={k}>
              <div className="title">
                <span className="must">{secondLayModifier.required?'必選':''}</span>請選擇{secondLayModifier.title}<i className="fas fa-caret-down"></i>
              </div>
              <div className="option_list">
                {/* {console.log('modifierState:',modifierState)} */}
                {secondLayModifier.options.map((option,l)=>{

                  // option.price
                  return(
                    <div key={l} onClick={()=>chooseSecondLayerOption(k,option,{
                      item_name:modifierState.title,
                      item_id:modifierState.id,
                      price:modifierState.price,
                      group_id:option.group_id
                      },l)} className="the_radio">
                      
                      {/* {console.log('secondModifierTemp:::',secondModifierTemp)} */}
                      <input checked={secondModifierTemp[k]?typeof secondModifierTemp[k].checkedIndexs=='object'?secondModifierTemp[k].checkedIndexs[l]:false:false}  type="radio" name={'a'+k} id={'a'+k+l} value={option.title}/>
                      <label htmlFor={'a'+k+l}>{option.title}{option.price==0?'':option.price>0?'+'+option.price:option.price}</label>
                    </div>
                  )
                  
                })}
              
              </div>
            </div>
            )
          })}
          <div className="sys_btn flex_center">
            <a onClick={addSecondLayerModifier} className="confirm" rel="modal:close">選好了</a>
          </div>
        </div>
      </div>

      
      <div style={finishMsg?{display:'block'}:{display:'none'}} className="jquery-modal blocker current">
        {/* <div style={finishMsg?{display:"inline-block"}:{display:"none"}} id="finish_msg" className="jquery-modal.blocker modal finish_msg"> */}
        <div style={{display:"inline-block"}} id="finish_msg" className="jquery-modal.blocker modal finish_msg">
          <h1>已成功下單！</h1>
          <h2>取餐編號：{postOrderInfo?postOrderInfo.orderNumber:''}</h2>
          <h3>桌號：{tableNumber}</h3>
          <h4>訂單編號：{postOrderInfo?postOrderInfo.orderId:''}</h4>
          <div className="order">
            {
              orders.map((order,i)=>{
            // addMoney(order.price)
                if(order){
                  return(
                    <a key={i}>
                      <div className="name">{order.title}</div>
                      {
                        order.modifiers?
                        <div className="options">
                          {
                            
                          }
                          {order.modifiers.map((modifier)=>{
                          // console.log(modifier)
                          if(modifier&&modifier.item_name){
                            return (<i>{modifier.item_name}</i>)
                          }
                          })}
                        </div>:''
                      }
                      {
                        order.note?
                          <div className="options">
                            <i>{order.note}</i>
                          </div>:''
                      }
                      <div className="subtotal flex_center">
                        <div className="input_quantity flex_center">
                          {order.count?order.count:1}
                        </div>
                        <div className="price">
                          {/* <del>${order.count?order.count*(order.price+30):(order.price+30)}</del>  */}
                          $<b>{order.count?order.count*(order.price):(order.price)}</b>
                        </div>
                      </div>
                    </a>

                    // <a key={i}>
                    //   <div className="name">{order.title}</div>
                    //   {order.modifiers?
                    //   <div className="options">{order.modifiers.map((modifier)=>{
                    //     // console.log(modifier)
                    //     if(modifier&&modifier.item_name){
                    //       return (<i>{modifier.item_name}</i>)
                    //     }
                    //   })}</div>:''}
                    //   <div className="subtotal flex_center">
                    //     <div className="input_quantity flex_center">
                    //       <button onClick={()=>minusNumber(order)} className='plus_and_minus'><i className="fas fa-minus"></i></button>
                    //       <input value={order.count?order.count:1} onChange={handleChangeNumber} type="number" />
                    //       <button onClick={()=>addNumber(order)} className='plus_and_minus'><i className="fas fa-plus"></i></button>
                    //     </div>
                    //     <div className="price">
                    //       <del>${order.price+30}</del> $<b>{order.price}</b>
                    //     </div>
                    //   </div>
                    // </a>
                  )
                  
                }else{
                  console.log('error order::',order)
                }
              })
            }
            
          </div>

          <div className="sys_btn flex_center">
            <a onClick={afterFinishOrder} className="confirm" rel="modal:close">知道了</a>
          </div>
        </div>
      </div>

      <div style={noteMsg?{display:'block'}:{display:'none'}} className='jquery-modal blocker current'>
        <div style={{display:'inline-block'}} id="food_memo" className="modal food_memo">
          <div className="crumbs">{categorytate&&getCategoryDetail(categorytate)?getCategoryDetail(categorytate).title:''} > {productState&&getProductDetail(productState)?getProductDetail(productState).title:''}</div>

            <textarea value={noteTextArea} onChange={(event)=>setNoteTextArea(event.target.value)} placeholder="備註"></textarea>

            <div className="sys_btn flex_center">
              <a onClick={addNote} className="confirm" rel="modal:close">填好了</a>
            </div>
          </div>
      </div>

      <div style={packageNoteMsg?{display:'block'}:{display:'none'}} className='jquery-modal blocker current'>
        <div style={{display:'inline-block'}} id="food_memo" className="modal food_memo">
          <div className="crumbs">訂單備註</div>

            <textarea value={packageNoteTextArea} onChange={(event)=>setPackageNoteTextArea(event.target.value)} placeholder="備註"></textarea>

            {/* 自動填上取餐時間 */}
            {/* <div>
              <button class="packageNoteTextButton" onClick={()=>{getNowTimeInputToNoteTextArea(15)}}>取餐十五分鐘後</button>
              <button class="packageNoteTextButton" onClick={()=>{getNowTimeInputToNoteTextArea(30)}}>取餐三十分鐘後</button>
              <button class="packageNoteTextButton" onClick={()=>{getNowTimeInputToNoteTextArea(60)}}>取餐一小時後</button>
            </div> */}
             {/* 輸入手機號碼  */}
            {/* <div style={{display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center'}}>
              <div style={{width:'80%',display:'flex',justifyContent:'center'}}>
                {[...Array(3).keys()].map((i)=>{
                  return (<button class="TextareaNumber" onClick={()=>addNumberTextToTextarea(i+1)}>{i+1}</button>)
                })}
              </div>
              <div style={{width:'80%',display:'flex',justifyContent:'center'}}>
                {[...Array(3).keys()].map((i)=>{
                  return (<button class="TextareaNumber" onClick={()=>addNumberTextToTextarea(i+4)}>{i+4}</button>)
                })}
              </div>
              <div style={{width:'80%',display:'flex',justifyContent:'center'}}>
                {[...Array(3).keys()].map((i)=>{
                  return (<button class="TextareaNumber" onClick={()=>addNumberTextToTextarea(i+7)}>{i+7}</button>)
                })}
              </div>
              <div style={{width:'80%',display:'flex',justifyContent:'center'}}>
                <button class="TextareaNumber" onClick={()=>addNumberTextToTextarea('0')}>{0}</button>
              </div>
            </div>
            <div style={{width:'100%',display:'flex',justifyContent:'center'}}>
              <button class="packageNoteTextButton2" onClick={()=>{
                   setPackageNoteTextArea(packageNoteTextArea.slice(0, -1))
              }}><i class="fas fa-undo"></i> 回刪</button>
              <button class="packageNoteTextButton2" onClick={()=>{
                   setPackageNoteTextArea(packageNoteTextArea+'\n')
              }}><i class="fas fa-level-down-alt"></i> 換行</button>
               <button class="packageNoteTextButton2" onClick={()=>{
                   setPackageNoteTextArea('')
              }}><i class="fas fa-trash"></i> 清空</button>
            </div> */}
            <div className="sys_btn flex_center">
              <a onClick={()=>{
                setPackageNote(packageNoteTextArea)
                setPackageNoteMsg(false)
              }} className="confirm" rel="modal:close">填好了</a>
            </div>
          </div>
      </div>

      <div style={{display:requiredMsg?'block':'none',padding:'130px 25px 95px 25px'}} className='jquery-modal blocker current'>
        <div style={{display:'inline-block',minHeight:"200px"}} id="food_memo" className="modal food_memo">
          <div style={{display:'flex',justifyContent:'center',fontSize:'30px'}} className="crumbs">
            [{orders[orderIndex]?orders[orderIndex].title:''}]
          </div>
          <div style={{display:'flex',justifyContent:'center',fontSize:'25px',marginTop:'50px',wordWrap:'break-word'}} className="crumbs">
            請點選必選
            {/* {console.log(orders[orderIndex].modifierGroupIds)} */}
            {orders[orderIndex]&&orders[orderIndex].modifierGroupIds?'(':''}
            {orders[orderIndex]&&orders[orderIndex].modifierGroupIds?orders[orderIndex].modifierGroupIds.map((m,i)=>{
              // if(getModifierDetail(m.id).required()
              if(getModifierDetail(m.id)&&getModifierDetail(m.id).required){
                if(i==0){
                  // if(i==1){
                    return(getModifierDetail(m.id).title)
                  // }
                  // else{
                  //   return('('+getModifierDetail(m.id).title+'/')
                  // }
                // }else if(i!==orders[orderIndex].modifierGroupIds.length-1){
                //   return(getModifierDetail(m.id).title+'/')
                }else{
                  return('/'+getModifierDetail(m.id).title)
                }
              }else{
                return ''
              }
            }):''}
            {orders[orderIndex]&&orders[orderIndex].modifierGroupIds?')':''}
          </div>
          
          <div style={{fontSize:'25px'}} className="sys_btn flex_center">
            <a style={{background:'white',color:'#f0941d',border:'1px solid',marginRight:'10px'}} onClick={()=>{
              let newOrders = [...orders]
              if(newOrders[orderIndex].count){
                addMoney(-newOrders[orderIndex].count*newOrders[orderIndex].price)
              }else{
                addMoney(-newOrders[orderIndex].price)
              }
              newOrders.splice(orderIndex,1)
              setOrders(newOrders)
              if(indexedDBCanWork){
                storeToIndexedDB(newOrders)
              }
              setOrderIndex(orderIndex-1)

              setRequiredMsg(false)
            }} className="confirm" rel="modal:close">放棄此品項</a>
            <a onClick={()=>setRequiredMsg(false)} className="confirm" rel="modal:close">回去點選</a>
            
          </div>
        
        </div>
      </div>

      <div style={offlineMsg[0]?{display:'block'}:{display:'none'}} className='jquery-modal blocker current'>
        <div style={{display:'inline-block',minHeight:'calc(100% - 600px)'}} id="off-line-msg" className="modal food_memo">
          <div style={{display:'flex',justifyContent:'center'}} className="crumbs">
            {offlineMsg[1]}
          </div>
          <div style={{display:'flex',justifyContent:'center'}} className="crumbs">
            {offlineMsg[2]}
          </div>
          <div className="sys_btn flex_center">
            <a onClick={()=>{
              setOfflineMsg([false,'',''])
              history.push(`/login`);
            }} className="confirm" rel="modal:close">重新登入</a>
          </div>
          {/* <a onClick={()=>setOfflineMsg([false,'',''])} href="#close-modal" rel="modal:close" class="close-modal ">Close</a> */}

        </div>
      </div>

      <div style={errorOrderMsg?{display:'block'}:{display:'none'}} className='jquery-modal blocker current'>
        <div style={{display:'inline-block',minHeight:'calc(100% - 600px)'}} id="off-line-msg" className="modal food_memo">
          <div style={{display:'flex',flexDirection:'column',alignItems:'center',justifyContent:'center'}} className="crumbs">
            <div>{'送單失敗，請稍候再試'}</div>
            {errorOrderMsgContent?<div>({errorOrderMsgContent})</div>:''}
          </div>

          <div className="sys_btn flex_center">
            <a onClick={()=>{
              setErrorOrderMsg(false)
              setErrorOrderMsgContent('')
            }} className="confirm" rel="modal:close">好的</a>
          </div>
          {/* <a onClick={()=>setErrorOrderMsg(false)} href="#close-modal" rel="modal:close" class="close-modal "></a> */}

        </div>
      </div>

      {/* <div style={loadingMsg?{display:'block'}:{display:'none'}}  className='jquery-modal blocker current'> */}
        <div style={loadingMsg?{display:'inline-block'}:{display:'none'}}  className="loading_area">
          <div style={{display:'flex',justifyContent:'center',alignItems:'center',height:'100%'}}>
            <ReactLoading type={'bubbles'} color={'#f0941d'} height={'10%'} width={'10%'} />           
          </div>
        </div>
      {/* </div> */}

      <div style={discountMsg?{display:'block'}:{display:'none'}} className='jquery-modal blocker current'>
        <div style={{display:'inline-block'}} id="discount_set" className="modal discount_set">
          {/* <div className="crumbs">{categorytate&&getCategoryDetail(categorytate)?getCategoryDetail(categorytate).title:''} > {productState&&getProductDetail(productState)?getProductDetail(productState).title:''} + {modifierState&&modifierState.title?modifierState.title:''}</div> */}
          <div className="title">
            手動折扣
            <i className="fas fa-caret-down"></i>
          </div>
          <div className="option_list">
            <div className="the_radio">
              <input checked={discountWholeWay=='manual_whole'} type="radio" name="dd" id="d4" value="整理訂單" />
              <label for="d4">整筆訂單</label>
            </div>
            {/* 先comment掉，因為現在指定商品的折價還沒做，之後做了補回來 */}
            {/* <div className="the_radio">
              <input type="radio" name="dd" id="d5" value="整理訂單" />
              <label for="d5">指定商品：{productState?getProductDetail(productState).title:''}</label>
            </div> */}
          </div>
          <div className="title discount_detail">
            折扣方式
            <i className="fas fa-caret-down"></i>
          </div>
          <div className="option_list discount_detail" style={{display: "block"}}>
          <div>
            <div onClick={()=>{
              setDiscountSumWay('percent')
              setDiscountMoneySum('')
            }} className="the_radio">
              <input checked={discountSumWay=='percent'} type="radio" name="ee" id="e1" value="％折抵"/>
              <label for="e1">％折抵</label>
            </div>
            <input value={discountPercentSum} onChange={sumPercentDiscount} style={{textAlign:'center'}} type="number" /> 折
          </div>

          <div>
            <div  onClick={()=>{
              setDiscountSumWay('money')
              setDiscountPercentSum('')
            }} className="the_radio">
              <input checked={discountSumWay=='money'} type="radio" name="ee" id="e2" value="金額折抵"/>
              <label for="e2">金額折抵</label>
            </div>
            <input value={discountMoneySum} onChange={sumMoneyDiscount} style={{textAlign:'center'}} type="number" /> 元
          </div>
          <div className="sys_btn flex_center">
            <a onClick={addDiscount} className="confirm" rel="modal:close">設定好了</a>
          </div>
          <a onClick={()=>setDiscountMsg(false)}  rel="modal:close" className="close-modal ">Close</a>
        </div>
          {/* <div style={{display:'flex',justifyContent:'center'}} className="crumbs">
            {offlineMsg[1]}
          </div>
          <div style={{display:'flex',justifyContent:'center'}} className="crumbs">
            {offlineMsg[2]}
          </div>
          <div className="sys_btn flex_center">
            <a onClick={()=>setOfflineMsg([false,'',''])} className="confirm" href="#" rel="modal:close">好的我知道了</a>
          </div> */}
        </div>
      </div>

      
    </div>
  );
}

export default Counter;
