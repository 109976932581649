// var request = require("request");
var axios = require('axios');
var crypto = require("crypto");
var Q = require('q');
var qs = require('querystring');

module.exports = function (envConfig) {
  function generateHmac(data, iiotSecretKey, algorithm, encoding) {
    encoding = encoding || "base64";
    algorithm = algorithm || "sha256";
    // aws signer v4
    return crypto.createHmac(algorithm, iiotSecretKey).update(data).digest(encoding);
  }
  this.get = function (url, query, options, config) {
    var deferred = Q.defer();
    var d = new Date().toUTCString();
    var org = 'IIOT';
    if (config.data)
      d = config.data;
    if (config.org) {
      org = config.org;
    }
    var params = {
      "url": envConfig.endpoint + url,
      "headers": {
        // "Date": d, // Must pass the Date header as well as the X-Amzn-Authorization header
        "Authorization": org + " " + config.iiotAccessKeyId +
          ":" + generateHmac(d, config.iiotSecretKey),
        "Content-Type": "application/json"
      }
    };
    params.params = query;
    params.responseType = 'json';
    axios(params)
      .then(function (response) {
        // console.log(response)
        if (response.status && response.status >= 400) {
          let message = 'Something went wrong. Please contact us for further technical support.';
          if (typeof response.data === 'string')
            message = response.data;
          else if (response.data && response.data.error && response.data.error.message)
            message = response.data.error.message;
          else if (response.data && response.data.message)
            message = response.data.message;
          var err = new Error(message);
          throw err;
        }
        deferred.resolve(response.data);
      })
      .catch(function (e) {
        if (e.response && e.response.data) {
          if (e.response.data.message) {
            e.message = e.response.data.message;
          }
        } 
        deferred.reject(e);
      })
    return deferred.promise;
  }
  this.post = function (url, data, options, config) {
    var deferred = Q.defer();
    if (!config)
      config = {};
    var d = new Date().toUTCString();
    var org = 'IIOT';
    if (config && config.data)
      d = config.data;
    if (config.org) {
      org = config.org;
    }
    var params = {
      "method": "POST",
      "url": envConfig.endpoint + url,
      "headers": {
        "Date": d, // Must pass the Date header as well as the X-Amzn-Authorization header
        "Authorization": org + " " + config.iiotAccessKeyId +
          ":" + generateHmac(d, config.iiotSecretKey)
      }
    };
    if (options && options.isForm) {
      params.headers['Content-Type'] = 'application/x-www-form-urlencoded';
      params.data = qs.stringify(data);
      // params.form = data;
      // params.json = true;
    } else {
      params.headers['Content-Type'] = 'application/json';
      params.data = JSON.stringify(data);
    }
    axios(params)
      .then(function (response) {
        // console.log(response)
        if (response.status && response.status >= 400) {
          let message = 'Something went wrong. Please contact us for further technical support.';
          if (typeof response.data === 'string')
            message = response.data;
          else if (response.data && response.data.error && response.data.error.message)
            message = response.data.error.message;
          else if (response.data && response.data.message)
            message = response.data.message;
          var err = new Error(message);
          throw err;
        }
        deferred.resolve(response.data);
      })
      .catch(function (e) {
        if (e.response && e.response.data) {
          if (e.response.data.message) {
            e.message = e.response.data.message;
          }
        } 
        deferred.reject(e);
      })
    return deferred.promise;
  }
  this.put = function (url, data, options, config) {
    var deferred = Q.defer();
    if (!config)
      config = {};
    var d = new Date().toUTCString();
    var org = 'IIOT';
    if (config && config.data)
      d = config.data;
    if (config.org) {
      org = config.org;
    }
    var params = {
      "method": "PUT",
      "url": envConfig.endpoint + url,
      "headers": {
        "Date": d, // Must pass the Date header as well as the X-Amzn-Authorization header
        "Authorization": org + " " + config.iiotAccessKeyId +
          ":" + generateHmac(d, config.iiotSecretKey)
      }
    };
    if (options && options.isForm) {
      params.headers['Content-Type'] = 'application/x-www-form-urlencoded';
      params.data = qs.stringify(data);
      // params.form = data;
      // params.json = true;
    } else {
      params.headers['Content-Type'] = 'application/json';
      params.data = JSON.stringify(data);
    }
    axios(params)
      .then(function (response) {
        // console.log(response)
        if (response.status && response.status >= 400) {
          let message = 'Something went wrong. Please contact us for further technical support.';
          if (typeof response.data === 'string')
            message = response.data;
          else if (response.data && response.data.error && response.data.error.message)
            message = response.data.error.message;
          else if (response.data && response.data.message)
            message = response.data.message;
          var err = new Error(message);
          throw err;
        }
        deferred.resolve(response.data);
      })
      .catch(function (e) {
        if (e.response && e.response.data) {
          if (e.response.data.message) {
            e.message = e.response.data.message;
          }
        } 
        deferred.reject(e);
      })
    return deferred.promise;
  }
  this.patch = function (url, data, options, config) {
    var deferred = Q.defer();
    if (!config)
      config = {};
    var d = new Date().toUTCString();
    var org = 'IIOT';
    if (config && config.data)
      d = config.data;
    if (config.org) {
      org = config.org;
    }
    var params = {
      "method": "PATCH",
      "url": envConfig.endpoint + url,
      "headers": {
        "Date": d, // Must pass the Date header as well as the X-Amzn-Authorization header
        "Authorization": org + " " + config.iiotAccessKeyId +
          ":" + generateHmac(d, config.iiotSecretKey)
      }
    };
    if (options && options.isForm) {
      params.headers['Content-Type'] = 'application/x-www-form-urlencoded';
      params.data = qs.stringify(data);
      // params.form = data;
      // params.json = true;
    } else {
      params.headers['Content-Type'] = 'application/json';
      params.data = JSON.stringify(data);
    }
    axios(params)
      .then(function (response) {
        // console.log(response)
        if (response.status && response.status >= 400) {
          let message = 'Something went wrong. Please contact us for further technical support.';
          if (typeof response.data === 'string')
            message = response.data;
          else if (response.data && response.data.error && response.data.error.message)
            message = response.data.error.message;
          else if (response.data && response.data.message)
            message = response.data.message;
          var err = new Error(message);
          throw err;
        }
        deferred.resolve(response.data);
      })
      .catch(function (e) {
        if (e.response && e.response.data) {
          if (e.response.data.message) {
            e.message = e.response.data.message;
          }
        } 
        deferred.reject(e);
      })
    return deferred.promise;
  }
  this.del = function (url, data, options, config) {
    var deferred = Q.defer();
    if (!config)
      config = {};
    var d = new Date().toUTCString();
    var org = 'IIOT';
    if (config && config.data)
      d = config.data;
    if (config.org) {
      org = config.org;
    }
    var params = {
      "method": "DELETE",
      "url": envConfig.endpoint + url,
      "headers": {
        "Date": d, // Must pass the Date header as well as the X-Amzn-Authorization header
        "Authorization": org + " " + config.iiotAccessKeyId +
          ":" + generateHmac(d, config.iiotSecretKey)
      }
    };
    if (options && options.isForm) {
      params.headers['Content-Type'] = 'application/x-www-form-urlencoded';
      params.data = qs.stringify(data);
      // params.form = data;
      // params.json = true;
    } else {
      params.headers['Content-Type'] = 'application/json';
      params.data = JSON.stringify(data);
    }
    axios(params)
      .then(function (response) {
        // console.log(response)
        if (response.status && response.status >= 400) {
          let message = 'Something went wrong. Please contact us for further technical support.';
          if (typeof response.data === 'string')
            message = response.data;
          else if (response.data && response.data.error && response.data.error.message)
            message = response.data.error.message;
          else if (response.data && response.data.message)
            message = response.data.message;
          var err = new Error(message);
          throw err;
        }
        deferred.resolve(response.data);
      })
      .catch(function (e) {
        if (e.response && e.response.data) {
          if (e.response.data.message) {
            e.message = e.response.data.message;
          }
        } 
        deferred.reject(e);
      })
    return deferred.promise;
  }
  return this;
}