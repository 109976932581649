import {useEffect,useState} from 'react';
// import { Tag,Card,PageHeader } from 'antd';
// import { Form, Input, Button, Checkbox } from 'antd';
import {
    Redirect,useHistory 
  } from "react-router-dom";
import ReactLoading from 'react-loading';

import api from  '../../services/api';
import styled from 'styled-components';
import logo_qc from '../images/new/qc.png';
import logo_counter from '../images/new/counter.svg';
import logo_foodpanda from '../images/new/Foodpanda.png';
import logo_ubereats from '../images/new/UberEats.png';




const OrdersMain = styled.div`
  width:75%;
  // margin-left:-1%;
`

const Header = styled.div`
  height: 46px;
  background: #FFFFFF;
  display:flex;
  justify-content:space-around;
  align-items:center;
  border-bottom:1px solid #BDBDBD;
  border-right:1px solid #BDBDBD;
`

const Title = styled.p`
  font-family: Noto Sans TC;
  font-style: normal;
  font-size: 14px;
  line-height: 26px;
  flex: none;
  order: ${(props)=>props.order};
  flex-grow: 0;
  // margin: 0px 30px;
  color: ${(props)=>props.color?props.color:'black'};

`

const OrderLines = styled.div`
  display:flex;
  flex-direction:column;
  height:93vh;
  overflow-y: scroll;
  align-items: center;
  z-index:1;
  // width:70%;
  border-right:1px solid #BDBDBD;
  padding-right:15px;
  padding-left:15px;
  padding-bottom:15px;
`

const OrderLine = styled.div`
  /* Auto Layout */

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0px;

  position: static;
  width:100%;
  left: 0px;
  top: 0px;

  /* Gray/6 */

  background: ${props=>props.clicked?'white':'#F2F2F2'};
  border: ${props=>props.clicked?'1.5px solid #F0941D':''};
  border-radius: 5px;

  /* Inside Auto Layout */

  flex: none;
  order: 0;
  flex-grow: 0;
  margin-top:10px;
  z-index:1
`

const OrderDetail = styled.div`
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: ${props=>props.fontSize?props.fontSize:'18px'};
  line-height: 21px;
  display:flex;
  flex-direction: column;
  align-items: ${props=>props.position?props.position:'center'};
  word-break: break-all;
  width:${props=>props.width?props.width:'16.1%'};
`

const Icon = styled.i`
  color:${(props)=>props.color?props.color:'#828282'};
  margin:5px;
`

const Button = styled.button`
  /* Auto Layout */

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width:100%;  

  height: 56px;
  left: 972px;
  top: 20px;
  margin-top:10px;

  /* Main */

  background: #F0941D;

  /* Inside Auto Layout */

  flex: none;
  order: 7;
  flex-grow: 0;
  color:white;
`


const OrderContent = styled.div`
  width:28%
`

const OrderContentLines = styled.div`
  /* Auto Layout */

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 10px;

  position: static;
  width: 100%;
  // height: 328px;
  height:50vh;
  overflow-y: scroll;

  left: 0px;
  top: 0px;

  flex: none;
  order: 0;
  flex-grow: 0;
  // margin: 256px 0px;
  
`

const OrderContentLine = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width:100%;
  margin-bottom:10px;
`

const ModifierTags = styled.div`
  display:flex;
  justify-content:sketch;
  flex-wrap:wrap;
  width:150px;
`

const ContentTitle = styled.div`
  display:flex;
  justify-content:sketch;
  flex-warp:wrap;
  width:150px;
`


const ModifierTag = styled.div`
  border: 1px solid #828282;
  box-sizing: border-box;
  border-radius: 99px;
  color: #828282;
  font-size: 12px;
  margin:2px;
  padding:2px;
  display: inline-block;
  width:fit-content;
  widht:-webkit-fit-content;
  width:-moz-fit-content;
  display:flex;
  justify-content:center;
`

const OrderContentDetail = styled.div`
  display:flex;
  flex-direction:column;
  margin:5px;
  justify-content:flex-start;
  align-items:center;
`

const AmountLine = styled.div`
  display:flex;
  justify-content:space-between;
  width:100%;
  // margin:10px;
  padding:5px;

`

const Msg = styled.div`
  display:flex;
  justify-content:center;
  align-items:center;
  z-index:3;
  postion:absolute;
`



function OrderComponent(props) {
    const [orders,setOrders]=useState([]);
    const [reprintMsg,setReprintMsg]=useState([false,''])
    const [orderId,setOrderId]=useState('')
    const [nowOrder,setNowOrder]=useState({})
    const [nowOrderDiscount,setNowOrderDiscount] = useState(0)
    const [invoiceStatus,setInvoiceStatus] = useState('')
    const [statusReturn,setStatusReturn] = useState({
        "availableToIssue": true,
        "availableToPrint": true
    })
    const [invoiceInformation,setInvoiceInformation] = useState({})
    const [patchOrderMoney,setPatchOrderMoney] = useState('')
    const [patchOrderMoneyPositiveOrNagative,setPatchOrderMoneyPositiveOrNagative] = useState(true)

    const [isDDCInvoiceAccount,setIsDDCInvoiceAccount] = useState(false)
    const [searchString,setSearchString] = useState('')
    // const [platformSelectMsg,setPlatformSelectMsg] = useState(true)
    const [platformChoose,setPlatformChoose] = useState({counter:true,quickclick:true,others:true})
    const [errorMsg,setErrorMsg] = useState([false,''])
    const [showLoading,setShowLoading] = useState(false)

    // 快一點要轉成其他拉皮
    const [customTitleAndLogo,setCustomTitleAndLogo] = useState({})
    const [doubleCheckMsg,setDoubleCheckMsg] = useState([false,''])



    const history = useHistory();


    useEffect(()=>{
      async function checkLogin(){
          let result = await api.checkLoginApi()
          let result2 = await api.enauth()

          console.log('enauth::',result2)

          if(result&&result.account_id){
              // history.push(`/counter/${result.account_id}`);
              console.log('login:::',result)
              await determineIsDDCInvoiceStore(result.account_id)
              // setAccount_id_from_session(result.account_id)
          }else{
            history.push(`/login`)
          }
      }
      checkLogin()

  },[])

    useEffect(()=>{
      async function getOrders(){
        console.log('props:',props)
        if(props.ordersShow){
          let result = await api.getOrders(props.account_id)
          console.log(123)
          let siTitleAndLogo = await api.getSiTitleLogo(props.account_id)
          console.log('siTitleAndLOHO',siTitleAndLogo)
          if(siTitleAndLogo&&siTitleAndLogo.title&&siTitleAndLogo.logo){
            console.log('siTitleAndTitle:',siTitleAndLogo)
            // siTitleAndLogo = {title: '快一點', logo: 'https://i2.wp.com/www.quickclick.cc/wp-content/uploads/2019/12/%E5%BF%AB%E4%B8%80%E9%BB%9Elogo_new-1.png'}
            // siTitleAndLogo = {title: '騰雲', logo: 'https://storage.googleapis.com/quickclick_products/library/208/web-aLXOZMGQl'}

            setCustomTitleAndLogo(siTitleAndLogo)
          }
          console.log(result)
          if(result.length){
            setOrders(result.reverse())
          }
        }
      }

      getOrders()
    },[props.ordersShow])

    useEffect(()=>{
        detechStatusOfThisOrderInvoice()
    },[statusReturn])

   

    const timeToAMPMTIME = (date) => {
      var hours = date.getHours();
      var minutes = date.getMinutes();
      var ampm = hours >= 12 ? 'PM' : 'AM';
      hours = hours % 12;
      hours = hours ? hours : 12; // the hour '0' should be '12'
      minutes = minutes < 10 ? '0'+minutes : minutes;
      var strTime = ampm + '  ' + hours + ':' + minutes ;
      return strTime;
    }

    const timeToDate = (date) => {
      var year  = (date.getYear()+1900).toString()
      var month = (date.getMonth()+1).toString()
      var day = date.getDate().toString()
      return year+'/'+month+'/'+day
    }
   
    let transformTime = function(timestamp){
      let date = new Date(timestamp)
    
      let dateStr = timeToDate(date)
      let timeStr = timeToAMPMTIME(date)
      return [dateStr,timeStr]
    }

    const reprint = async(orderId)=>{
      console.log(orderId,'->重印')
      try{
        let reprint_d = await api.reprint(orderId,props.user_info)
        if(reprint_d){
          console.log('重印成功')
          // alert('重印成功')
          setReprintMsg([true,'重印成功'])
        }else{
          console.log('重印失敗')
          // alert('重印失敗')
          setReprintMsg([true,'重印失敗'])

        }
        console.log('reprint_d:',reprint_d)
      }catch(e){
        setReprintMsg([true,'重印失敗'])

      }
    }

    async function detechStatusOfThisOrderInvoiceApi(order_id){
        // 這邊打狀態api
        console.log('detechStatusOfThisOrderInvoiceApi:',order_id)
        let statusApi = await api.getInvoiceStatusApi(order_id)
        let statusResults = statusApi.results
        console.log('status::',statusResults)
        
    
        // 假設回傳
        setStatusReturn(statusResults)
    }

    function detechStatusOfThisOrderInvoice(){
        
        
        // let statusReturn = {
        //     availableToIssue: false,
        //     availableToPrint: true,
        //     availableToCancel: true
        // }

        if(statusReturn){
            if(statusReturn.availableToIssue&&!statusReturn.availableToPrint){
            // 狀態一：開立且列印（畫面顯示開立且列印發票，沒有作廢）
                setInvoiceStatus('issueAndPrint')
            }else if(!statusReturn.availableToIssue&&statusReturn.availableToPrint){
            // 狀態二：列印（畫面顯示列印發票，沒有作廢）
                setInvoiceStatus('print')
            }else if(!statusReturn.availableToIssue&&!statusReturn.availableToPrint&&statusReturn.availableToCancel){
            // 狀態三：報廢（畫面列印發票無法按，下方有作廢）
                setInvoiceStatus('cancel')
            }else{
                setInvoiceStatus('cannotIsuueAndPrintAndCancel')
                // console.log('availableToIssue和availableToPrint都為true,有bug')
                // 畫面顯示只有掃描
                // setInvoiceStatus('issueAndPrint')
            }
        }
    }

    const determineIsDDCInvoiceStore = async(account_id)=>{
        let isDDCInvoiceStore = await api.isDDCInvoiceStoreApi(account_id)
        console.log('isDDCInvoiceStoreResult:',isDDCInvoiceStore)
        if(isDDCInvoiceStore&&isDDCInvoiceStore.result&&isDDCInvoiceStore.result.length){
            if(isDDCInvoiceStore.result[0].is_ddc_invoice==1){
                setIsDDCInvoiceAccount(true)
            }else{
                setIsDDCInvoiceAccount(false)
            }
        }
    }

    const cancelOrder = async(order_id) => {
        try{
            setShowLoading(true)

            await api.cancelOrder(order_id)
            await api.cancelIssueApi(order_id)
            // setErrorMsg([true,'取消訂單成功'])
            setReprintMsg([true,'取消訂單成功'])
            // 重打一次訂單和發票狀態
            // await getOrderApi(queryStringParse.order_id)
            await clickOrderContent(orderId)
            setShowLoading(false)

        }catch(e){
            setShowLoading(false)

            console.log(e.message)
            console.log(e)
            // setErrorMsg([true,'取消訂單失敗'])
            setReprintMsg([true,'取消訂單失敗'])
          
        }
    }

    const translateFromOrderToPostIsuueBody = (nowOrderInput) => {
      console.log('nowOrderInput:',nowOrderInput)

      let returPostBody = {
          // "buyerTaxId": "12345678",
          // "carrierType": "3J0002",
          // "carrierId": "/X99PBR6",
          "items": [
            {
              "id": `${nowOrderInput.orderId}`,
              "name": "餐點費用",
              "unitPrice": nowOrderInput.amount,
              "qty": 1,
              "taxRate": 0.05
              // "id": "product0001",
              // "name": "餐點費用",
              // "unitPrice": 20,
              // "qty": 1,
              // "taxRate": 0.05
            }
          ]
      }
      // 有統編
      if(nowOrderInput.user&&nowOrderInput.user.taxId){
          returPostBody.buyerTaxId = nowOrderInput.user.taxId
      }
      console.log('nowOrderInput.user',nowOrderInput.user)
      // 有載具
      if(nowOrderInput.user&&nowOrderInput.user.carrierId&&nowOrderInput.receipt&&nowOrderInput.receipt.carrierType){
          returPostBody.carrierId = nowOrderInput.user.carrierId
          if(nowOrderInput.receipt.carrierType=='mobile'){
            returPostBody.carrierType = "3J0002"
          }
      }

      return returPostBody

  }

  const issueAndPrintInvoice = async(order_id) => {

      try{
          setShowLoading(true)

          let postBody = translateFromOrderToPostIsuueBody(nowOrder)
          // 這打issueApi且printApi如果成功後
          let postIssueApi = await api.postIssueApi(order_id,postBody)
          console.log('postIssueApi::',postIssueApi)
           // 手機載具有的話，列印會報錯，所以不要執行列印
          let printInvoiceApi = api.printIssueApi(order_id)
          console.log('printInvoiceApi::',printInvoiceApi)
          // 重新打一次狀態api
          let statusApi = await api.getInvoiceStatusApi(order_id)
          let statusResults = statusApi.results
          console.log('status::',statusResults)
          setStatusReturn(statusResults)
          setErrorMsg([true,'開立且列印發票成功'])

          // 重打一次訂單和發票狀態
          // await getOrderApi(queryStringParse.order_id)
          await clickOrderContent(orderId)
          setShowLoading(false)

      }catch(e){
          console.log(e)
          setShowLoading(false)
          setErrorMsg([true,'開立且列印發票失敗'])

      }
      
      // 假設成功應該會回傳
      // setStatusReturn({
      //     "availableToIssue": false,
      //     "availableToPrint": false
      // })
  }

  const cancelIssueInvoice = async(order_id) => {
    try{
      setShowLoading(true)

      let cancelIssueApi = await api.cancelIssueApi(order_id)
      console.log('cancelIssueApi::',cancelIssueApi)
      // 重新打一次狀態api
      let statusApi = await api.getInvoiceStatusApi(order_id)
      let statusResults = statusApi.results
      console.log('status::',statusResults)
      setStatusReturn(statusResults)
      setErrorMsg([true,'作廢發票成功'])
      await clickOrderContent(orderId)
      setShowLoading(false)
    }catch(e){
      console.log(e)
      setShowLoading(false)

      setErrorMsg([true,'作廢發票失敗'])
    }
  }
  

  const cancelAndIssueAndPrintInvoice = async(order_id) => {
      try{
          setShowLoading(true)

          let postBody = translateFromOrderToPostIsuueBody(nowOrder)
          // 這打issueApi且printApi如果成功後
          let cancelIssueApi = await api.cancelIssueApi(order_id)
          console.log('cancelIssueApi::',cancelIssueApi)
          let postIssueApi = await api.postIssueApi(order_id,postBody)
          console.log('postIssueApi::',postIssueApi)
          // 手機載具有的話，列印會報錯，所以不要執行列印
          let printInvoiceApi = api.printIssueApi(order_id)
          console.log('printInvoiceApi::',printInvoiceApi)
          // 重新打一次狀態api
          let statusApi = await api.getInvoiceStatusApi(order_id)
          let statusResults = statusApi.results
          console.log('status::',statusResults)
          setStatusReturn(statusResults)
          setErrorMsg([true,'重開發票成功'])

          // 重打一次訂單和發票狀態
          // await getOrderApi(queryStringParse.order_id)
          await clickOrderContent(orderId)
          setShowLoading(false)
      }catch(e){
          console.log(e)
          setShowLoading(false)

          setErrorMsg([true,'重開發票失敗'])
          

      }
  }

  const printInvoice = async(order_id) => {
      try{
          setShowLoading(true)

          // 這打printApi如果成功後
          let printInvoiceApi = await api.printIssueApi(order_id)
          console.log('printInvoiceApi::',printInvoiceApi)
          // 重新打一次狀態api
          let statusApi = await api.getInvoiceStatusApi(order_id)
          let statusResults = statusApi.results
          console.log('status::',statusResults)
          setStatusReturn(statusResults)
          setErrorMsg([true,'列印發票成功'])

          // 假設成功應該會回傳
          // setStatusReturn({
          //     "availableToIssue": false,
          //     "availableToPrint": false
          // })
          setShowLoading(false)

      }catch(e){
          console.log(e)
          setShowLoading(false)

          setErrorMsg([true,'列印發票失敗'])
      }
  }


    const clickOrderContent = async(order_id) => {
      console.log(order_id)
      let newOrder = await api.getOrder(order_id)
      console.log('newOrder:',newOrder)
      setNowOrder(newOrder)
      setOrderId(order_id)
      let sumDiscount = 0
      if(newOrder.discounts && newOrder.discounts.length) {
        newOrder.discounts.forEach((discount)=>{
          if(discount.amount){
            sumDiscount = sumDiscount+discount.amount
          }
        })
      }
      setNowOrderDiscount(sumDiscount)

      // 以下是增加發票
      if(isDDCInvoiceAccount){
        // 取得發票狀態
        console.log('取得發票狀態!!!')
        let invoiceInformationResult = await api.getInvoiceInformationApi(order_id)
        console.log('invoiceInformationResult:',invoiceInformationResult)
        if(invoiceInformationResult && invoiceInformationResult.result && invoiceInformationResult.result.length){
            setInvoiceInformation(invoiceInformationResult.result[0])
            console.log('invoiceInformation:',invoiceInformationResult.result[0])

        } else {
          // 沒拿到發票狀態
          console.log('沒拿到發票狀態')
          setInvoiceInformation({})
        }
        // 判斷畫面狀態
        await detechStatusOfThisOrderInvoiceApi(order_id)
      }

      // orders.forEach((order)=>{
      //   if(order_id==order.orderId){
      //     let newOrder = await api.getOrder(props.order_id)
      //     setNowOrder(newOrder)
      //     setOrderId(order_id)
      //     let sumDiscount = 0
      //     newOrder.discounts.forEach((discount)=>{
      //       sumDiscount = sumDiscount+discount.amount
      //     })
      //     setNowOrderDiscount(sumDiscount)
      //   }
       
      // })
     
    }

    // 調帳
    const patchOrder = async(order_id) => {
      console.log('調帳：',order_id,patchOrderMoney,patchOrderMoneyPositiveOrNagative)
      console.log(Number(patchOrderMoney))
      if(!Number(patchOrderMoney)){
          setErrorMsg([true,'請輸入正確數字'])
      }else{
          try{
              setShowLoading(true)

              let returnBody = {
                  "amount": 0, // 欲調整之訂單金額
                  "comments": { // 關於此筆調整的相關紀錄
                    "storeComments": "",
                    "systemLogs": ""
                  }
              }
              if(patchOrderMoneyPositiveOrNagative){
                  returnBody.amount = nowOrder.amount+Number(patchOrderMoney)
              }else{
                  returnBody.amount = nowOrder.amount-Number(patchOrderMoney)
              }
              if(props.user_info&&props.user_info.username){
                  returnBody.comments.systemLogs = props.user_info.username
              }
              console.log('調帳內容:',returnBody)
              
              // 打調帳api
              await api.patchOrder(order_id,returnBody)

              setPatchOrderMoneyPositiveOrNagative(true)
              setPatchOrderMoney('')

              let result = await api.getOrders(props.account_id)
              console.log(result)
              if(result.length){
                setOrders(result.reverse())
              }
             
              await clickOrderContent(order_id)
              
              // setNowOrder({...nowOrder,amount:returnBody.amount})
              setShowLoading(false)
              setErrorMsg([true,'調帳成功'])


          }catch(e){
            setShowLoading(false)
            setErrorMsg([true,'調帳失敗'])
            console.log('調帳失敗',e)
          }
      }
      

  }

  const inputPatchMoney = (e) =>{
      setPatchOrderMoney(e.target.value)
  }

  const patchOrderMoneyPositiveOrNagativeFun = ()=>{
    if(patchOrderMoneyPositiveOrNagative){
      setPatchOrderMoneyPositiveOrNagative(false)
    }else{
      setPatchOrderMoneyPositiveOrNagative(true)
    }
  }

  const doubleCheckFun = async(functionName) => {
    if(functionName=='重開發票再次確認'){
      if(invoiceStatus=='issueAndPrint'){
        await issueAndPrintInvoice(orderId)
      }else if(invoiceStatus=='print'){
        await printInvoice(orderId)
      }else if(invoiceStatus=='cancel'){
        await cancelAndIssueAndPrintInvoice(orderId)
      }else{
        setErrorMsg([true,'此訂單狀態無法開立、列印發票'])
        console.log('不能列印，不能開立')
      }
    }else if(functionName=='取消訂單再次確認'){
      await cancelOrder(nowOrder.orderId)
    }else if(functionName=='調帳再次確認'){
      await patchOrder(nowOrder.orderId)
    }
  }

    return (
        
        <div style={{display:'flex'}}>
            <OrdersMain>
              <Header>
                <Title order={0}>訂單平台</Title>
                <Title order={0}>訂單編號</Title>
                <Title order={1}>下單時間</Title>
                <Title order={2}>取餐編號</Title>
                <Title order={3}>付款方式</Title>
                <Title order={4}>取餐方式</Title>
                {/* <Title order={5}>餐點內容</Title> */}
                <Title order={6}>訂單總價</Title>
                {/* <Title order={7} color='#F2994A'>重印</Title> */}
              </Header>
              <OrderLines>
                {orders.map((order,i)=>{
                  return (
                    <OrderLine clicked={orderId==order.order_id} onClick={()=>clickOrderContent(order.order_id)} key={i}>
                      <OrderDetail style={{height:'100%',paddingRight:'10px',display: 'flex',justifyContent:'center'}}>{order.platform=='counter'?
                        <div style={{width:'35px'}}>
                          <img style={{width:'100%',height:'100%'}} src={logo_counter}/>
                        </div>:
                        order.platform=='quickclick'?
                        <div style={{
                            height: '50px',
                            backgroundColor: 'white',
                            borderRadius: '5px',
                            width: '50px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                          }}>
                            <div style={{
                              borderRadius: '5px',
                              maxWidth: '60px',
                              maxHeight: '60px',
                              backgroundColor: 'white',
                              display:'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                              padding: '5px',                              
                            }}>
                              <img style={{width:'100%',height:'100%'}} src={customTitleAndLogo&&customTitleAndLogo.logo?customTitleAndLogo.logo:logo_qc}/>
                            </div>
                        </div>
                        :
                        order.platform=='ubereats'?
                        <div style={{width:'35px'}}>
                          <img style={{width:'100%',height:'100%',borderRadius:'10px'}} src={logo_ubereats}/>
                        </div>
                        :
                        order.platform=='foodpanda'?
                        <div style={{width:'35px'}}>
                          <img style={{width:'100%',height:'100%',borderRadius:'10px'}} src={logo_foodpanda}/>
                        </div>
                        :
                        <div style={{width:'35px',fontSize:'10px',display:'flex',justifyContent:'center',alignItems:'center',flexDirection:'column'}}>
                          <div>其他</div>
                          <div>平台</div>
                        </div>}
                      </OrderDetail>

                      <OrderDetail style={{fontSize:'12px',paddingRight:'10px'}}>{order.order_id}</OrderDetail>
                      <OrderDetail><div style={{fontSize:'14px'}}>{transformTime(order.created_at)[0]}</div><div style={{fontSize:'18px'}}>{transformTime(order.created_at)[1]}</div></OrderDetail>
                      <OrderDetail>
                        <div style={{
                          border: '1.5px solid #828282',
                          'boxSizing': 'border-box',
                          'borderRadius': '5px',
                          minWidth:'31px',
                          height: '31px',
                          display: 'flex',
                          justifyContent:'center',
                          alignItems:'center'
                          }}>{order.order_number}</div>
                        </OrderDetail>
                      <OrderDetail fontSize={'14px'}><Icon className={order.payment_types==='cash'?"fas fa-cash-register":"fas fa-credit-card"}></Icon>{order.payment_types=='cash'?'現金結帳':'行動支付'}</OrderDetail>
                      <OrderDetail fontSize={'14px'}><Icon className={order.meal_types==='to_go'?"fas fa-shopping-bag":"fas fa-chair"}/>{order.meal_types=='to_go'?'外帶':'內用'}</OrderDetail>
                      {/* <OrderDetail position={'left'} fontSize={'14px'}>{order.carts.map((product)=>{
                        return (
                          <div>x{product.quantity} {product.title}</div>
                        )
                      })}</OrderDetail> */}
                      <OrderDetail style={{color:'#CB5A4B'}}>${order.amount}</OrderDetail>
                      {/* <OrderDetail><Button onClick={()=>reprint(order.orderId)}><Icon color="white" className="fas fa-print"/></Button></OrderDetail> */}
                    </OrderLine>
                  )
                })}
              </OrderLines>
            </OrdersMain>
            <OrderContent>
              <Header>
                {/* <Title color={'#F2994A'}>餐點內容</Title> */}
                <input style={{width:'100%',paddingLeft:'10px'}} placeholder="搜尋訂單編號" onChange={(event)=>{
                  setSearchString(event.target.value)
                }}/>
                
                {/* <button> */}
                  <Icon onClick={async()=>{
                    if(searchString){
                      let result = await api.getOrders(props.account_id,searchString)
                      console.log(result)
                      if(result.length){
                        setOrders(result.reverse())
                      }
                    }else{
                      let result = await api.getOrders(props.account_id)
                      console.log(result)
                      if(result.length){
                        setOrders(result.reverse())
                      }
                    }
                  }} style={{paddingRight:'10px'}} color="#BDBDBD" className="fas fa-search"/>
                {/* </button> */}

              </Header>
              <div style={{display:orderId?'block':'none'}}>
                  {nowOrder.carts?
                  <OrderContentLines style={{height:isDDCInvoiceAccount?'42vh':'55vh'}}>{nowOrder.carts.map((product,i)=>{
                    let price = product.price
                    return (
                      <OrderContentLine key={i}>
                        <OrderContentDetail>x{product.quantity}</OrderContentDetail>
                         
                        <OrderContentDetail style={{width:'80%',width:'100%'}}>
                          <ContentTitle>{product.title}</ContentTitle>
                          <ModifierTags>
                            {product.selectedModifierGroups.map((sMG,i)=>{
                              return(
                                sMG.selectedModifierOptions.map((sMO,j)=>{
                                  if(sMO.price){
                                    price = price + sMO.price
                                  }
                                  let title = sMO.title
                                  sMO.selectedModifierGroups.forEach((sMG2,k)=>{
                                    sMG2.selectedModifierOptions.forEach((sMO2,l)=>{
                                      if(k==0&&l==0){
                                        title = title + '('
                                      }
                                      if((k+1)*(l+1)==sMG2.selectedModifierOptions.length*sMO.selectedModifierGroups.length){
                                        title = title + sMO2.title + ')'
                                      }else{
                                        title = title + sMO2.title +'/'
                                      }
                                      if(sMO2.price){
                                        price = price + sMO2.price
                                      }
                                    })
                                  })
                                  return <ModifierTag key={i+j}>{title}</ModifierTag>
                                })
                              )
                            })}
                          </ModifierTags>
                        </OrderContentDetail>

                        <OrderContentDetail style={{fontWeight:'bold'}}>${price*product.quantity}</OrderContentDetail>

                      </OrderContentLine>
                    )
                  })}</OrderContentLines>:''}
                 
              </div>
              
              <div style={{display: 'flex', width:isDDCInvoiceAccount?'50%':'100%'}}>
                {nowOrder&&nowOrder.currentState&&nowOrder.currentState=='cancelled'?
                  <Button style={{margin:'1px',backgroundColor:'#BDBDBD',pointerEvents:'none',height:'70px',display:orderId?'block':'none'}} onClick={()=>reprint(orderId)}><Icon color="white" className="fas fa-print"/>重印訂單</Button>:
                  <Button style={{margin:'1px',height:'70px',display:orderId?'block':'none'}} onClick={()=>reprint(orderId)}><Icon color="white" className="fas fa-print"/>重印訂單</Button>
                }
                {(nowOrder&&nowOrder.currentState&&nowOrder.currentState=='cancelled')||!invoiceStatus?
                  <Button style={{margin:'1px',backgroundColor:'#BDBDBD',pointerEvents:'none',height:'70px',display:orderId&&isDDCInvoiceAccount?'block':'none'}} onClick={()=>cancelAndIssueAndPrintInvoice(orderId)}><Icon color="white" className="fas fa-receipt"/>重開發票</Button>:
                  <Button style={{margin:'1px',height:'70px',display:orderId&&isDDCInvoiceAccount?'block':'none'}} onClick={async()=>{
                    // if(invoiceStatus=='issueAndPrint'){
                    //   await issueAndPrintInvoice(orderId)
                    // }else if(invoiceStatus=='print'){
                    //   await printInvoice(orderId)
                    // }else if(invoiceStatus=='cancel'){
                    //   await cancelAndIssueAndPrintInvoice(orderId)
                    // }else{
                    //   setErrorMsg([true,'此訂單狀態無法開立、列印發票'])
                    //   console.log('不能列印，不能開立')
                    // }
                    setDoubleCheckMsg([true,'重開發票再次確認','重開發票為作廢原發票，請確認已回收原字軌發票紙本，確認重開發票？'])
                  }}><Icon color="white" className="fas fa-receipt"/>重開發票</Button>
                } 
              </div>
              <div style={{display:orderId?'block':'none'}} >
                {/* 這是發票相關的 */}
                <div style={{display:orderId&&isDDCInvoiceAccount?'block':'none'}} >
                  <AmountLine>
                    <div>發票</div>
                    <div style={{fontWeight:'bold'}}>{invoiceInformation&&invoiceInformation.invoice_number?invoiceInformation.invoice_number:''}</div>
                  </AmountLine>
                  <AmountLine>
                    <div>統編</div>
                    <div style={{fontWeight:'bold'}}>{nowOrder.user?nowOrder.user.taxId:''}</div>
                  </AmountLine>
                  <AmountLine>
                    <div>載具</div>
                    <div style={{fontWeight:'bold'}}>{nowOrder.user?nowOrder.user.carrierId:''}</div>
                  </AmountLine>
                </div>
                {/* 以上發票相關的 */}
                <AmountLine>
                  <div>小計</div>
                  <div style={{fontWeight:'bold'}}>${nowOrder.amount+nowOrderDiscount}</div>
                </AmountLine>
                <AmountLine>
                  <div>訂單折扣</div>
                  <div style={{fontWeight:'bold',color:'#CB5A4B'}}>-${nowOrderDiscount}</div>
                </AmountLine>
                <AmountLine>
                  <div>總計</div>
                  <div style={{fontWeight:'bold'}}>${nowOrder.amount}</div>
                </AmountLine>
                <AmountLine style={{alignItems:'center'}}>
                  <div style={{width:'65px'}}>調帳</div>
                  <div style={{backgroundColor:'#F2F2F2',borderRadius:'999px',padding:'3px',marginLeft:'5px',marginRight:'5px',width:'100%',display:'flex'}}>
                    <div style={{backgroundColor:'#FFFFFF',borderRadius:'999px',padding:'3px',display:'flex'}}>
                      <div onClick={()=>patchOrderMoneyPositiveOrNagativeFun()}><i style={{color:patchOrderMoneyPositiveOrNagative?'#F0941D':'#E0E0E0'}} class="fas fa-plus fa-sm"></i></div>
                      <div onClick={()=>patchOrderMoneyPositiveOrNagativeFun()}><i style={{color:patchOrderMoneyPositiveOrNagative?'#E0E0E0':'#F0941D'}} class="fas fa-minus fa-sm"></i></div>
                    </div>
                    <div style={{padding:'5px',borderRadius:'15px',backgroundColor:'#F2F2F2'}}>
                      <input onChange={inputPatchMoney} style={{backgroundColor:'#F2F2F2',width:'100%'}} value={patchOrderMoney}/>
                    </div>
                  </div>
                  <div onClick={()=>{
                    // patchOrder(nowOrder.orderId)
                    setDoubleCheckMsg([true,'調帳再次確認'])
                  }} style={{backgroundColor:'#F0941D',color:'white',padding:'7px 5px',width:'137px',textAlign:'center',fontSize:'12px'}}>確認調帳</div>
                </AmountLine>
                {/* <AmountLine> */}
                  {/* <div style={{width:'100%'}}>調帳：</div> */}
                  {/* <div style={{fontWeight:'bold'}}>${nowOrder.amount}</div> */}
                  {/* <div style={{display: 'flex',justifyContent:'center'}} className="input">
                      <a className="switch itemH">
                          <div className="icon"><i class="fas fa-plus fa-sm"></i></div>
                          <div className="icon off"><i class="fas fa-minus fa-sm"></i></div>
                      </a> */}
                    {/* <div> */}
                      {/* <span className="fM margin">$</span> */}
                      {/* <input type="text" className="fM" placeholder="0"/>
                      <a style={{backgroundColor:'#F0941D',color:'white',width:'30px'}} className="button"><span class="fM">確認</span></a> */}
                  {/* </div> */}
                    {/* </div> */}
                {/* </AmountLine> */}
              </div>
              {(nowOrder&&nowOrder.currentState&&nowOrder.currentState=='cancelled')?
              <div style={{backgroundColor:'#BDBDBD',pointerEvents:'none',width:'100%',display:'flex',display:orderId?'flex':'none'}}>
                  <a style={{width: '100%',height: '50px',padding: '0 10px',margin: '5px',display:'flex',justifyContent: 'center',alignItems: 'center',color: 'white'}} id="buttonDelete" class="button delete">
                      <div class="icon"><i class="far fa-times-circle error"></i></div>
                      <span class="fL">取消訂單</span>
                  </a>
              </div>:
              ((nowOrder.platform=='ubereats')||(nowOrder.platform=='foodpanda'))?
              <div onClick={()=>cancelIssueInvoice(nowOrder.orderId)} style={{backgroundColor:'#CB5A4B',width:'100%',display:'flex',display:orderId?'flex':'none'}}>
                  <a style={{width: '100%',height: '50px',padding: '0 10px',margin: '5px',display:'flex',justifyContent: 'center',alignItems: 'center',color: 'white'}} id="buttonDelete" class="button delete">
                      <div class="icon"><i class="far fa-times-circle error"></i></div>
                      <span class="fL">作廢發票</span>
                  </a>
              </div>:
              <div onClick={()=>setDoubleCheckMsg([true,'取消訂單再次確認','取消訂單即作廢發票，請確認已回收原字軌發票紙本，確認取消訂單？'])} style={{backgroundColor:'#CB5A4B',width:'100%',display:'flex',display:orderId?'flex':'none'}}>
                  <a style={{width: '100%',height: '50px',padding: '0 10px',margin: '5px',display:'flex',justifyContent: 'center',alignItems: 'center',color: 'white'}} id="buttonDelete" class="button delete">
                      <div class="icon"><i class="far fa-times-circle error"></i></div>
                      <span class="fL">取消訂單</span>
                  </a>
              </div>
              }
            </OrderContent>

            <div style={reprintMsg[0]?{display:'block'}:{display:'none'}} className="jquery-modal blocker current">
              {/* <div style={{postion:'fixed'}} className='jquery-modal.blocker modal finish_msg'> */}
                {/* {reprintMsg[0]? */}
                  <Msg style={{display:"inline-block",minHeight:'40%'}} id="finish_msg"  className="jquery-modal.blocker modal finish_msg">
                    <div className="order" style={{display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center',paddingTop:'12%'}}>
                      <h1>{reprintMsg[1]}</h1>
                      <h3>訂單編號：{orderId}</h3>
                    </div>
                    {/* {reprintMsg[1]} */}
                    {/* <button onClick={()=>setReprintMsg([false,''])}>確定</button> */}
                    <div className="sys_btn flex_center">
                      <a onClick={()=>setReprintMsg([false,''])} className="confirm" rel="modal:close">確定</a>
                      {/* <a onClick={afterFinishOrder} className="confirm" rel="modal:close">知道了</a> */}
                    </div>
                  </Msg>
                {/* :''} */}
              {/* </div> */}
            </div>

            {/* 訂單列表中訂單平台的選擇，預選全部勾選 */}
            <div style={props.platformSelectMsg?{display:'block'}:{display:'none'}} className="jquery-modal blocker current">
              {/* <div style={{postion:'fixed'}} className='jquery-modal.blocker modal finish_msg'> */}
                {/* {reprintMsg[0]? */}
                  <Msg style={{display:"inline-block",minHeight:'40%',height:'300px'}} id="finish_msg"  className="jquery-modal.blocker modal finish_msg">
                    <div style={{width:'100%',display:'flex',justifyContent:'space-between',fontSize:'20px',padding:'15px'}}>
                      <p style={{fontSize:'20px'}}>訂單平台顯示</p>
                      <a class="close" onClick={()=>props.closePlatformSelectMsgFun()}><i class="fas fa-times"></i></a>
                    </div>
                    <div style={{display:'flex',justifyContent:'center',width:'100%',padding:'10px'}}>
                      <div onClick={()=>{
                        let newPlatformChoose = {...platformChoose}
                        newPlatformChoose['counter'] = !newPlatformChoose['counter']
                        console.log('newPlatformChoose',newPlatformChoose)
                        setPlatformChoose(newPlatformChoose)
                      }} style={{width:'33%',height:'100px',margin:'10px',display:'flex',justifyContent:'center',alignItems:'center',flexDirection:'column',backgroundColor:'#FFFFFF',borderRadius:'10px'
                      ,border: platformChoose['counter']?'2px solid #F0941D':''}}>
                        <div style={{borderRadius:'61.875px',width:'50px'}}>
                          <img style={{width:'50px',height:'50px'}} src={logo_counter}/>
                        </div>
                        <span class="fL">愛點餐飲</span>
                      </div>
                      <div onClick={()=>{
                        let newPlatformChoose = {...platformChoose}
                        newPlatformChoose['quickclick'] = !newPlatformChoose['quickclick']
                        console.log('newPlatformChoose',newPlatformChoose)
                        setPlatformChoose(newPlatformChoose)
                      }} style={{width:'33%',height:'100px',margin:'10px',display:'flex',justifyContent:'center',alignItems:'center',flexDirection:'column',backgroundColor:'#FFFFFF',borderRadius:'10px'
                      ,border: platformChoose['quickclick']?'2px solid #F0941D':''}}>                  
                        <div style={{
                            height: '54px',
                            backgroundColor: 'white',
                            borderRadius: '5px',
                            width: '50px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                          }}>
                            <div style={{
                              borderRadius: '5px',
                              maxWidth: '60px',
                              maxHeight: '60px',
                              backgroundColor: 'white',
                              display:'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                              padding: '5px',                              
                            }}>
                            <img style={{width:'100%',height:'100%'}} src={customTitleAndLogo&&customTitleAndLogo.logo?customTitleAndLogo.logo:logo_qc}/>
                          </div>
                        </div>
                        <span class="fL">{customTitleAndLogo&&customTitleAndLogo.title?customTitleAndLogo.title:'快一點'}</span>
                      </div>
                      <div onClick={()=>{
                        let newPlatformChoose = {...platformChoose}
                        newPlatformChoose['others'] = !newPlatformChoose['others']
                        console.log('newPlatformChoose',newPlatformChoose)
                        setPlatformChoose(newPlatformChoose)
                      }} style={{width:'33%',height:'100px',margin:'10px',display:'flex',justifyContent:'center',alignItems:'center',flexDirection:'column',backgroundColor:'#FFFFFF',borderRadius:'10px'
                      ,border: platformChoose['others']?'2px solid #F0941D':''}}>                  
                        <div>
                          其他<br/>平台
                        </div>
                      </div>
                    </div>
                    <div style={{display:'flex',width:'100%',padding:'10px',justifyContent:'space-between'}}>
                      <div onClick={()=>props.closePlatformSelectMsgFun()} style={{width:'50%',height:'40px',backgroundColor:'white',display:'flex',justifyContent:'center',alignItems:'center',backgroundColor:'rgb(243, 243, 243)'}}>取消</div>
                      <div onClick={async()=>{
                        // 重新抓取訂單
                        let result = await api.getOrders(props.account_id,'',platformChoose)
                        console.log('result~~~',result)

                        setOrders(result.reverse())
                        // 關閉視窗
                        props.closePlatformSelectMsgFun()
                      }}  style={{width:'50%',height:'40px',backgroundColor:'#F0941D',color:'white',display:'flex',justifyContent:'center',alignItems:'center'}}>確認</div>
                    </div>
                  </Msg>
                {/* :''} */}
              {/* </div> */}
            </div>
            <div style={errorMsg[0]?{display:'block'}:{display:'none'}} className="jquery-modal blocker current">
              <Msg style={{display:"inline-block",minHeight:'40%'}} id="finish_msg"  className="jquery-modal.blocker modal finish_msg">
                    <div className="order" style={{display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center',paddingTop:'12%'}}>
                      <h1>{errorMsg[1]}</h1>
                      <h3>訂單編號：{orderId}</h3>
                    </div>
                    {/* {reprintMsg[1]} */}
                    {/* <button onClick={()=>setReprintMsg([false,''])}>確定</button> */}
                    <div className="sys_btn flex_center">
                      <a onClick={()=>setErrorMsg([false,''])} className="confirm" rel="modal:close">確定</a>
                      {/* <a onClick={afterFinishOrder} className="confirm" rel="modal:close">知道了</a> */}
                    </div>
              </Msg>
            </div>

            <div style={doubleCheckMsg[0]?{display:'block'}:{display:'none'}} className="jquery-modal blocker current">
              <Msg style={{display:"inline-block",minHeight:'40%'}} id="finish_msg"  className="jquery-modal.blocker modal finish_msg">
                    <div className="order" style={{display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center',paddingTop:'12%'}}>
                      <h1>{doubleCheckMsg[1]}</h1>
                      <h3>訂單編號：{orderId}</h3>
                    </div>
                    <div style={{display:orderId&&isDDCInvoiceAccount?'block':'none',marginTop:'20px',marginLeft:'100px',marginRight:'100px'}}>{doubleCheckMsg[2]}</div>
                    {/* {reprintMsg[1]} */}
                    {/* <button onClick={()=>setReprintMsg([false,''])}>確定</button> */}
                    <div className="sys_btn flex_center">
                      <a onClick={()=>setDoubleCheckMsg([false,''])} className="confirm" style={{background:'white',color:'#f0941d',border:'1px solid #f0941d',marginRight:'5px'}} rel="modal:close">取消</a>
                      <a onClick={()=>{doubleCheckFun(doubleCheckMsg[1]);setDoubleCheckMsg([false,''])}} className="confirm" rel="modal:close">確定</a>
                      {/* <a onClick={afterFinishOrder} className="confirm" rel="modal:close">知道了</a> */}
                    </div>
              </Msg>
            </div>


            <div style={showLoading?{display:'inline-block'}:{display:'none'}}  className="loading_area">
              <div style={{display:'flex',justifyContent:'center',alignItems:'center',height:'100%'}}>
                <ReactLoading type={'bubbles'} color={'#f0941d'} height={'10%'} width={'10%'} />           
              </div>
            </div>

        </div>

    );
  }
  
  export default OrderComponent;