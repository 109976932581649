import logo from './logo.svg';
import React, { useState, useEffect } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
} from "react-router-dom";

import './App.css';
// import {Profile,Home} from './pages'
// import Home from './pages/Home'
import Login from './pages/login/Login'
// import Counter from './pages/Counter'
import Counter from './pages/counter/Counter'




function App() {



  const [updateOrNot,setUpdateOrNot] = useState(true)

  function getCookie(name){
    let arr = document.cookie.match(new RegExp("(^| )" + name + "=([^;]*)(;|$)"));
    if (arr != null) return unescape(arr[2]); 
    return null;
  }

  function SetCookie(name,value,date){
    let Days = date;//此 cookie 將被儲存的天數
    let exp  = new Date();//建立一個時間日期物件;
    exp.setTime(exp.getTime() + Days*24*60*60*1000);
    document.cookie = name + "="+ escape (value) + ";expires=" + exp.toGMTString();
  }

  useEffect(()=>{
    var version = getCookie('version')
    console.log('version現在版本:',version)

        
    function updateSite() {
        // window.location.reload();
        window.location.href = window.location.href;

    }
    
    if(updateOrNot){
      // 抓取遠端後端版本號，如果不同就更新寫入
      console.log(version,'更新版本：',`${process.env.REACT_APP_backend_api_url}/version`)
      fetch(`${process.env.REACT_APP_backend_api_url}/version`)
      .then(function(response) {
        return response.json();
      })
      .then(function(myJson) {
        console.log(myJson);
        if(myJson.version){
          console.log(`現在網頁版本：${version}/線上最新版本：${myJson.version}`)
          if(version!==myJson.version){
            console.log(`更新`)
            SetCookie('version',myJson.version,365)
            setUpdateOrNot(true)
            updateSite()

            
            // setUpdateOrNot(true)
          }else{
            console.log('不用更新')
          }
        }else{
          console.log('讀取更新版本api錯誤')
        }
      }).catch(err => console.log('更新錯誤：',err))
    }else{
      console.log('已更新完畢')
    }
    
  },[])
  


  return (
    <Router>
      <div>
      {/*
        <nav>
          <ul>
            <li>
              <Link to="/">Home</Link>
            </li>
            <li>
              <Link to="/profile">Profile</Link>
            </li>
          </ul>
        </nav>
      */}

        {/* A <Switch> looks through its children <Route>s and
            renders the first one that matches the current URL. */}
        <Switch>
          {/* <Route path='/counter3'>
            <Counter3 />
          </Route> */}
          {/* <Route exact path='/counter'>
            <Counter />
          </Route> */}
          <Route path="/login">
            <Login />
          </Route>
          <Route path="/counter">
            <Counter />
          </Route>
          {/* <Route path="/counter/">
            <Counter />
          </Route> */}
          <Route path="/">
            <Login />
          </Route>
          
        </Switch>
      </div>
    </Router>
  );
}

export default App;
